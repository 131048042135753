import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

//helpers
import { isEmpty, fetchWrapper } from '../../../../_helpers';
import { getOtherUserData } from '../../../../_redux/actions/ActionUser';
import { CreateAEmployee, EditAEmployee } from '../../../../_redux/actions/ActionEVV';

// components
import ProviderSelector from '../ProviderSelector';
import ProviderChannelSelector from '../ProviderChannelSelector';

import { VscLoading } from 'react-icons/vsc';
import EvvFormBuilder from '../components/EvvFormBuilder';
import UserSelection from '../components/UserSelection';

import { SandataStatusCheck } from '../Client/SandataStatusCheck';

const typeOptions = {
    evv: {
        sandata: { label: 'Sandata', value: 'sandata' },
        therap: { label: 'Therap', value: 'therap' },
        optonome: { label: 'Optonome', value: 'optonome' },
    },
    payroll: {
        // worklio: { label: 'Worklio', value: 'worklio' },
        zeal: { label: 'Zeal', value: 'zeal' },
        optonome: { label: 'Optonome', value: 'optonome' },
    },
};

const StaffForm = ({
    formStructure,
    setShowModal,
    getOtherUserData,
    CreateAEmployee,
    EditAEmployee,
    employee,
    formType,
    provider: defaultProvider,
}) => {
    const [selectedOption, setSelectedOption] = useState(
        employee
            ? {
                  label: `${employee.firstName} ${employee.lastName}`,
                  value: employee.user?.id || employee.user?._id || employee.user?.toString(),
              }
            : null
    );
    const [user, setUser] = useState(employee?.user);
    const [userLoading, setUserLoading] = useState(false);
    const [providerId, setProviderId] = useState(
        employee ? employee?.provider?.id || employee?.provider : defaultProvider?.providerId
    );
    const [provider, setProvider] = useState(employee ? employee?.provider : defaultProvider);
    const [providerTeamId, setProviderTeamId] = useState(
        employee?.provider?.teamId || employee?.provider?.team?._id || defaultProvider?.teamId
    );
    const [employeeChannel, setEmployeeChannel] = useState(null);
    const [employeeNameError, setEmployeeNameError] = useState(null);
    const [providerError, setProviderError] = useState(null);

    const [submitLoading, setSubmitLoading] = useState(false);

    const [forceExpandAllEvv, setForceExpandAllEvv] = useState(false);

    // immediately set force expand all to false after it is set to true
    useEffect(() => {
        const timeout = setTimeout(() => {
            setForceExpandAllEvv(false);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [forceExpandAllEvv]);

    const { register, handleSubmit, errors, setValue, watch, setError, clearErrors, getValues } = useForm({
        // mode: 'onBlur',
    });

    const [type, setType] = useState({
        evv: employee?.evv?.type || provider?.evv?.type,
        payroll: employee?.payroll?.type || provider?.payroll?.type,
    });

    const [defaultData, setDefaultData] = useState({ evv: employee?.evv || {}, payroll: employee?.payroll || {} });
    const [isMapping, setIsMapping] = useState(false);

    const setTypeFunc = async (formType, value = '') => {
        if (!type.evv || !type.payroll || !value) {
            return;
        }
        const presentData = getValues();

        let mappedData = {};
        try {
            setIsMapping(true);
            const sameMappedData = await fetchWrapper.post(
                `evv/map/employee/${defaultData[formType]?.type}/${value}?team=${providerTeamId}`,
                (presentData[`_${formType}`] || {})[type[formType]]
            );
            const differentType = { evv: 'payroll', payroll: 'evv' }[formType];
            const differentMappedData = await fetchWrapper.post(
                `evv/map/employee/${defaultData[differentType]?.type}/${value}?team=${providerTeamId}`,
                (presentData[`_${differentType}`] || {})[type[differentType]]
            );
            mappedData = { ...differentMappedData, ...sameMappedData };
        } catch (error) {
        } finally {
            setIsMapping(false);
        }

        setDefaultData((prev) => ({ ...prev, [formType]: { type: value, data: mappedData } }));
        return setType({ ...type, [formType]: value });
    };

    useEffect(() => {
        if (selectedOption) {
            setEmployeeNameError(null);
        }
    }, [selectedOption]);

    useEffect(() => {
        const fectUserData = async () => {
            setUserLoading(true);
            let user = await getOtherUserData(selectedOption.value);
            if (user?.name && formType === 'create') {
                let FirstName = user?.name.split(' ')[0];
                let LastName = user?.name.split(' ')[user?.name.split(' ').length - 1];
                if (type?.evv === 'optonome') {
                    setValue('_evv.optonome.firstName', FirstName);
                    setValue('_evv.optonome.lastName', LastName);
                    setValue('_evv.optonome.email', user?.email);
                }
                if (type?.payroll === 'optonome') {
                    setValue('_payroll.optonome.firstName', FirstName);
                    setValue('_payroll.optonome.lastName', LastName);
                    setValue('_payroll.optonome.email', user?.email);
                }
            }
            setUserLoading(false);
            return setUser(user);
        };
        if (selectedOption?.value) {
            fectUserData();
        }
    }, [formType, setValue, selectedOption]);

    useEffect(() => {
        if ((formType === 'View' || formType === 'Edit') && !isEmpty(formStructure)) {
            if (formType === 'View' && employee?.user) {
                setSelectedOption({ label: employee.user.name, value: employee.user.id });
            }
            // setValue('staffRate', employee?.staffRate);
        }
    }, [setValue, formStructure, employee, formType, setSelectedOption]);

    const handleSelectProvider = (provider) => {
        setProviderId(provider.providerId);
        setProvider(provider);
        setType((prev) => ({
            evv: prev.evv || provider?.evv?.type || 'optonome',
            payroll: prev.payroll || provider?.payroll?.type || 'optonome',
        }));
        setProviderTeamId(provider.teamId);
        setProviderError(null);
    };

    const handleEmployeeChannelSelect = (employeeChannelId) => {
        setEmployeeChannel(employeeChannelId);
    };
    const onSubmit = async (data) => {
        try {
            setSubmitLoading(true);
            let body = {
                ...data,
            };
            let evv = {};
            let payroll = {};

            if (formType === 'Edit') {
                evv = { ...(employee.evv || {}), ...evv };
                payroll = { ...(employee.payroll || {}), ...payroll };
            }
            if (!user) {
                setEmployeeNameError('Please enter user name');
                return toast.error('User is required');
            }
            if (!providerId) {
                setProviderError('Please select Provider!');
                return toast.error('Provider must not be empty!');
            }
            // if (!validateRateFormat(body.staffRate)) {
            // 	return toast.error("Please enter valid rate");
            // }
            if (body._evv) {
                evv = {
                    type: type?.evv,
                    data: body._evv?.[type?.evv],
                };
                body.firstName = evv.data.firstName || evv.data.EmployeeFirstName;
                body.lastName = evv.data.lastName || evv.data.EmployeeLastName;
                body.middleName = evv.data.middleName;
            }

            const selectedEmployeeBasicInfo = { ...(employee?.user || {}), ...(selectedOption || {}) };
            if (body._payroll) {
                payroll = {
                    type: type?.payroll,
                    data: {
                        ...(!employee?.payroll?.metadata?.employeeID && !employee?.payroll?.metadata?.contractorID
                            ? {
                                  basic: {
                                      email: employee?.payroll?.data?.basic?.email || selectedEmployeeBasicInfo?.email,
                                      first_name:
                                          employee?.payroll?.data?.basic?.first_name ||
                                          (selectedEmployeeBasicInfo?.name || '')?.split(' ')[0],
                                      last_name:
                                          employee?.payroll?.data?.basic?.last_name ||
                                          (selectedEmployeeBasicInfo?.name || '')?.split(' ')[
                                              (selectedEmployeeBasicInfo?.name || '')?.split(' ').length - 1
                                          ],
                                  },
                              }
                            : {}),
                        ...(body._payroll?.[type?.payroll] || {}),
                    },
                };
            }
            body.user = user.id || user._id;
            body.provider = providerId;
            body._evv = evv;

            body.sendTo = type?.evv;
            body._payroll = payroll;

            let result;
            if (formType === 'Edit') {
                result = await EditAEmployee(body, employee._id ?? employee.id, providerTeamId);
            } else {
                if (employeeChannel) {
                    body.chatroom = employeeChannel;
                }
                result = await CreateAEmployee(body, providerTeamId);
            }

            if (result) {
                setShowModal(false);
            }
        } catch (e) {
            console.log('staff form error', e);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {employee?.evv?.type === 'sandata' && employee?.evv?.metadata?.uuid ? (
                <SandataStatusCheck evv={employee?.evv} type={'employee'} id={employee?.id} />
            ) : null}
            <div className={`${formType === 'Edit' ? 'abs_hidden' : ''} mb-4`}>
                {!providerId && <Alert variant="info">Please select the provider to assign the employee to.</Alert>}
                <ProviderSelector
                    isDisabled={formType === 'View' || !!defaultProvider}
                    selectProvider={handleSelectProvider}
                    provider={JSON.stringify(employee?.provider || defaultProvider)}
                    team={providerTeamId}
                />
                <p className="text-danger">{providerError}</p>
            </div>
            {providerId && (
                <>
                    {formType !== 'Edit' && (
                        <Fragment>
                            <UserSelection
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                employeeNameError={employeeNameError}
                                user={user}
                                userLoading={userLoading}
                                formType={formType}
                                teamId={defaultProvider?.teamId}
                            />
                            {formType === 'View' ? (
                                <Fragment>
                                    {employee?.chatroom?.id && (
                                        <div>
                                            <Form.Label>Employee Channel</Form.Label>
                                            <div>
                                                <a href={`/teams/${employee.chatroom.id}`}>
                                                    {employee?.chatroom?.title}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <ProviderChannelSelector
                                    type="Employee"
                                    providerId={providerId}
                                    providerTeamId={providerTeamId}
                                    isDisabled={formType === 'View'}
                                    selectChannel={handleEmployeeChannelSelect}
                                    // channel={JSON.stringify(client?.provider || {})}
                                />
                            )}
                        </Fragment>
                    )}

                    {/* <div className="my-3">
						<InputField
							data={{
								canEdit: true,
								fieldName: 'Employee Rate -e',
								name: 'staffRate',
								placeholder: 'eg: 20',
								required: true,
								type: 'string',
								as: 'input'
							}}
							isDisabled={formType === 'View'}
							errors={errors}
							elementRef={register}
							error={errors && errors[formStructure?.rate?.name]}
						/>
					</div> */}

                    <EvvFormBuilder
                        formType="evv"
                        formStructure={formStructure?.evv}
                        isEditMode={!isMapping && !submitLoading && formType === 'Edit'}
                        isViewMode={isMapping || submitLoading || formType === 'View'}
                        type={type?.evv}
                        setTypeFunc={setTypeFunc}
                        typeOptions={typeOptions}
                        allowedTypes={
                            provider?.evv
                                ? ['optonome', defaultData?.evv?.type || 'optonome'].concat(
                                      Object.keys(typeOptions?.evv).filter((key) => key === provider?.evv?.type)
                                  )
                                : ['optonome', defaultData?.evv?.type]
                        }
                        typeData={defaultData?.evv || {}}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        forceExpandAll={forceExpandAllEvv}
                    />
                    <hr style={{ border: '20px solid #ddd', borderRadius: '3px' }} />
                    <EvvFormBuilder
                        formType="payroll"
                        formStructure={
                            employee?.payroll?.metadata?.employeeID || employee?.payroll?.metadata?.contractorID
                                ? formStructure?.payroll
                                : {
                                      ...formStructure?.payroll,
                                      zeal: [
                                          ...(formStructure?.payroll?.zeal || []).filter((fieldGroup) =>
                                              ['employee', 'type', 'contractor'].includes(fieldGroup?.name)
                                          ),
                                      ],
                                  }
                        }
                        isEditMode={!isMapping && !submitLoading && formType === 'Edit'}
                        isViewMode={isMapping || submitLoading || formType === 'View'}
                        type={type?.payroll}
                        setTypeFunc={setTypeFunc}
                        typeOptions={typeOptions}
                        allowedTypes={
                            provider?.payroll
                                ? ['optonome', defaultData?.payroll?.type || 'optonome'].concat(
                                      Object.keys(typeOptions?.payroll).filter((key) => key === provider?.payroll?.type)
                                  )
                                : ['optonome', defaultData?.payroll?.type]
                        }
                        typeData={defaultData?.payroll || {}}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        itemData={{ provider: { ...provider, teamId: providerTeamId } }}
                        forceExpandAll={forceExpandAllEvv}
                    />

                    <div className="d-flex justify-content-center">
                        {formType === 'View' ? null : (
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={submitLoading}
                                onClick={() => {
                                    setForceExpandAllEvv(true);
                                }}
                            >
                                {submitLoading ? (
                                    <VscLoading className="spin" />
                                ) : (
                                    <Fragment>{formType === 'Edit' ? 'Edit' : 'Create'}</Fragment>
                                )}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </Form>
    );
};

const mapStateToProps = (state) => ({
    formStructure: state.evv.evvForms.employee,
});
export default connect(mapStateToProps, { getOtherUserData, CreateAEmployee, EditAEmployee })(StaffForm);
