import { css } from 'twin.macro';
import { Fragment, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { fetchWrapper, getStandardDate } from '../../../../../_helpers';
import { MdCancel } from 'react-icons/md';
import { getShiftBadge } from '../PayAllocation';

export const shiftTypes = {
    hourly: ['hours', 'wage'],
    flat: ['hours', 'amount'],
    overtime: ['hours', 'wage'],
    doubletime: ['hours', 'wage'],
    reimbursement: ['amount'],
    pto: ['hours', 'wage'],
    vacation: ['hours', 'wage'],
    sick: ['hours', 'wage'],
    holiday: ['hours', 'wage'],
    piecework: ['hours', 'units', 'amount_per_unit'],
    charged_tips: ['amount'],
    discretionary_bonus: ['amount'],
    nondiscretionary_bonus: ['amount'],
    commission: ['amount'],
    commodity: ['amount'],
    shareholder_healthcare_reimbursement: ['flat'],
    gross_up: ['desired_net'],
    correction: ['hours', 'wage'],
};

const getCustomName = (shift) => {
    try {
        const shiftNames = Object.keys(shiftTypes);
        let visitId;

        for (let i = 0; i < shiftNames.length; i++) {
            const name = shiftNames[i];
            if (shift[name]) {
                visitId = shift[name]?.custom_name || shift[name]?.reimbursementcustom_name;
                if (visitId) break;
            }
        }
        return visitId;
    } catch (error) {
        console.log('Error:', error);
    }
};

export const groupShiftsByVisit = (shifts = []) => {
    return (shifts || []).reduce((acc, shift) => {
        const visitId = shift?.custom_name || shift?.reimbursementcustom_name || getCustomName(shift);
        if (acc[visitId]) {
            acc[visitId].shifts.push(shift);
        } else {
            acc[visitId] = {
                shifts: [shift],
            };
        }
        return acc;
    }, {});
};

function getRandomId() {
    return `NEW_SHIFT_${Math.round(Math.random() * 1000)}`;
}

export const formatDateToLocalISO = (date) => {
    const offset = new Date(date).getTimezoneOffset();
    const localDate = new Date(new Date(date).getTime() - offset * 60 * 1000);
    return localDate.toISOString();
};

export function prepareFormData(formData) {
    try {
        const shiftsEntries = Array.from(formData.entries()).filter((entry) => entry[0].startsWith('shift-'));
        const shiftsData = {};
        shiftsEntries.forEach((entry) => {
            const [key, value] = entry;
            const [_, id, shiftField] = key.split('-');
            shiftsData[id] = {
                ...shiftsData[id],
                shiftID: id,
                [shiftField]:
                    shiftField === 'time'
                        ? new Date(value).toISOString()
                        : shiftField === 'metadata'
                          ? JSON.parse(value || '{}')
                          : value,
            };
        });
        return {
            employeeID: formData.get('employeeID'),
            reportingPeriodID: formData.get('reportingPeriodID'),
            check_date: formData.get('check_date'),
            approval_required: formData.get('approval_required') === 'on',
            disbursement: {
                method: formData.get('disbursement-method'),
            },
            // flsa_ot_recommendation: formData.get("flsa_ot_recommendation") === "on",
            speed: formData.get('speed'),
            shifts: Object.values(shiftsData),
        };
    } catch (error) {
        toast.error('Please check missing fields in form');
    }
}

export default function ZealEmployeeCheckForm({
    companyID,
    data,
    defaultCheckDate,
    workLocations,
    mode = 'create',
    onSubmit = () => {},
    onCreateCheckButton = () => {},
    isBusy: defaultBusy,
    teamId,
    initialReportingData,
    defaultReportingDataQuery = {},
}) {
    const [isBusy, setIsBusy] = useState(defaultBusy);
    const [checkDate, setCheckDate] = useState(data?.check_date);
    const [reportingPeriods, setReportingPeriods] = useState(initialReportingData || []);

    useEffect(() => {
        setIsBusy(defaultBusy);
    }, [defaultBusy]);

    const getStartDate = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 6);
        return currentDate.toISOString();
    };

    const [reportingScheduleQuery, setReportingScheduleQuery] = useState(
        mode === 'create'
            ? defaultReportingDataQuery
            : {
                  paySchedule: 'weekly',
                  startDate: getStandardDate(getStartDate()),
                  endDate: getStandardDate(new Date()),
              }
    );

    const [shifts, setShifts] = useState(
        data?.shifts?.map((shift) => ({ id: shift.shiftID || getRandomId(), ...shift })) || []
    );

    const visitWithShifts = groupShiftsByVisit(shifts);

    async function fetchReportingPeriods(reportingScheduleFetchQuery, reportingPeriodID) {
        try {
            if (mode === 'view' && reportingPeriods.length === 1) {
                return;
            }
            setIsBusy(true);
            const queryString = reportingPeriodID
                ? `reportingPeriodID=${reportingPeriodID}`
                : Object.entries(reportingScheduleFetchQuery)
                      .map(([key, value]) => `${key}=${value}`)
                      .join('&');
            const response = await fetchWrapper.get(
                `/evv/payroll/zeal/${companyID}/reporting-periods?${queryString}${teamId ? `&team=${teamId}` : ''}`
            );
            setReportingPeriods(Array.isArray(response) ? response : [response]);
            setReportingScheduleQuery({
                paySchedule: response?.pay_schedule || reportingScheduleFetchQuery?.paySchedule,
                startDate: response?.start || reportingScheduleFetchQuery?.startDate,
                endDate: response?.end || reportingScheduleFetchQuery?.endDate,
            });
        } catch (error) {
            if (error?.name === 'AbortError') return;
            toast.error(error?.message || "Reporting Periods couldn't be fetched");
        } finally {
            setIsBusy(mode === 'view');
        }
    }
    async function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        if (mode === 'create') {
            onCreateCheckButton(prepareFormData(formData));
        } else if (mode === 'edit') {
            return onSubmit(prepareFormData(formData));
        }
    }

    useEffect(() => {
        setReportingPeriods(initialReportingData);
    }, [initialReportingData]);

    useEffect(() => {
        if (companyID && mode !== 'create') {
            fetchReportingPeriods(reportingScheduleQuery, data.reportingPeriodID);
        }
    }, [companyID]);

    useEffect(() => {
        if (defaultCheckDate) {
            setCheckDate(defaultCheckDate);
        }
    }, [defaultCheckDate]);
    return (
        <Form
            onSubmit={handleSubmit}
            css={css`
                display: flex;
                flex-direction: column;
                gap: 6px;
            `}
            className="employee-payroll-form"
        >
            {/* Employee ID */}
            <Form.Control hidden readOnly value={data?.employeeID} name="employeeID" />
            {/* Reporting Period */}
            <div
                css={css`
                    display: flex;
                    gap: 8px;
                    align-items: flex-end;
                    padding: 12px;
                    border: 1px solid #eee;
                    justify-content: space-between;
                `}
            >
                <Form.Group
                    css={css`
                        width: 112px;
                    `}
                >
                    <Form.Label>Pay Schedule</Form.Label>
                    <Form.Control
                        as="select"
                        value={reportingScheduleQuery?.paySchedule}
                        onChange={(event) => {
                            fetchReportingPeriods({ ...reportingScheduleQuery, paySchedule: event.target.value });
                        }}
                        disabled={isBusy}
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="biweekly">Biweekly</option>
                        <option value="semimonthly">Semimonthly</option>
                        <option value="monthly">Monthly</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Reporting Date Range</Form.Label>
                    <div
                        css={css`
                            display: flex;
                            gap: 4px;
                            align-items: center;
                        `}
                    >
                        <Form.Control
                            type="date"
                            name="reporting-date-start"
                            value={reportingScheduleQuery?.startDate}
                            onChange={(event) => {
                                fetchReportingPeriods({ ...reportingScheduleQuery, startDate: event.target.value });
                            }}
                            disabled={isBusy}
                        />
                        <span>-</span>
                        <Form.Control
                            type="date"
                            name="reporting-date-end"
                            value={reportingScheduleQuery?.endDate}
                            onChange={(event) => {
                                fetchReportingPeriods({ ...reportingScheduleQuery, endDate: event.target.value });
                            }}
                            disabled={isBusy}
                        />
                    </div>
                </Form.Group>
                <Form.Group
                    css={css`
                        width: 242px;
                    `}
                >
                    <Form.Label>Reporting Period</Form.Label>
                    <Form.Control
                        as="select"
                        name="reportingPeriodID"
                        disabled={isBusy}
                        defaultValue={data?.reportingPeriodID}
                    >
                        {reportingPeriods?.map((reportingPeriod) => (
                            <option
                                value={reportingPeriod?.reportingPeriodID}
                                key={reportingPeriod?.reportingPeriodID}
                            >{`${reportingPeriod.start} - ${reportingPeriod.end}`}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>
            {/* Check Date and Disbursement*/}
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    padding: 12px;
                    border: 1px solid #eee;
                `}
            >
                <Form.Group
                    css={css`
                        width: 60%;
                    `}
                >
                    <Form.Label>Check Date</Form.Label>
                    <Form.Control
                        type="date"
                        name="check_date"
                        disabled={isBusy}
                        value={checkDate}
                        onChange={(e) => setCheckDate(e.target.value)}
                        required
                    />
                    <Form.Text>
                        If this date falls on a weekend/bank holiday, or the current time is after 2PM PST, two days
                        before this date, adjust this date to the next valid business day. Zeal will not roll forward
                        this date automatically.
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Disbursement Method</Form.Label>
                    <Form.Control
                        as="select"
                        name="disbursement-method"
                        disabled={isBusy}
                        defaultValue={data?.disbursement?.method}
                    >
                        <option value="direct_deposit">Direct Deposit</option>
                        <option value="download_check">Download Check</option>
                        <option value="prepaid">Prepaid</option>
                    </Form.Control>
                    <Form.Text>Direct Deposit: Make a direct deposit to the employee's bank account</Form.Text>
                    <Form.Text>Download Check: Get the link to a PDF version of the check</Form.Text>
                    <Form.Text>Prepaid: Do not disburse the net pay</Form.Text>
                </Form.Group>
            </div>
            {/* Approval and FLSA OT Recommendaion? and ACH Speed */}
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    padding: 12px;
                    border: 1px solid #eee;
                `}
            >
                <Form.Group>
                    <Form.Group
                        css={css`
                            display: flex;
                            gap: 6px;
                            align-items: center;
                        `}
                    >
                        <Form.Check
                            name="approval_required"
                            disabled={isBusy}
                            defaultChecked={data?.approval_required}
                        />
                        <Form.Label>Approval Required</Form.Label>
                    </Form.Group>
                    <Form.Text>
                        Set to true if this check requires manual approval by the employer. Otherwise, by default, Zeal
                        will automatically process the check on the day before the check date.
                    </Form.Text>
                </Form.Group>
                <Form.Group
                    css={css`
                        min-width: max-content;
                    `}
                >
                    <Form.Label>ACH Speed of the Check</Form.Label>
                    <Form.Control as="select" name="speed" disabled={isBusy} defaultValue={data?.speed}>
                        <option value="two_day">Two Day</option>
                        <option value="one_day">One Day</option>
                    </Form.Control>
                </Form.Group>
                {/* <Form.Group>
                    <Form.Group css={css`display: flex; gap: 6px; align-items: center;`}>
                        <Form.Check
                            name="flsa_ot_recommendation"
                            disabled={isBusy}
                            defaultChecked={data?.flsa_ot_recommendation}
                        />
                        <Form.Label>FLSA OT Recommendation</Form.Label>
                    </Form.Group>
                    <Form.Text>If set to true, Zeal will automatically convert the check to include our OT recommendation at the time of processing. (Only applicable for FLSA Overtime Policy)</Form.Text>
                </Form.Group> */}
            </div>
            {/* Shifts */}
            <hr />
            <span
                css={css`
                    font-size: 18px;
                    font-weight: bold;
                    padding-left: 6px;
                `}
            >
                Shifts:
            </span>
            {Object.values(visitWithShifts)
                ?.sort((a, b) => new Date(a?.shifts[0]?.time) - new Date(b?.shifts[0]?.time))
                ?.map((visitShifts, visitIndex) => {
                    const shiftGroupType = visitShifts?.shifts[0]?.employeeCheckID
                        ? visitShifts?.shifts[0]?.hourly
                            ? 'visit'
                            : visitShifts?.shifts[0]?.metadata?.label || ''
                        : JSON.parse(visitShifts?.shifts[0]?.metadata || '{}')?.label || '';
                    const shiftGroupClass = getShiftBadge(shiftGroupType);
                    const completedAt = new Date(visitShifts?.shifts[0]?.time).toDateString();
                    // const disableAddShift = visitShifts?.shifts[0]?.type === 'pto';
                    const disableAddShift = false;

                    return (
                        <div
                            css={css`
                                margin-top: 8px;
                                border-radius: 3px;
                                border: 1px solid #17a2b8;
                                padding: 8px;
                                box-shadow: 0px 0px 7px 2px #dddddd;
                                position: relative;
                            `}
                            key={visitIndex}
                        >
                            <div
                                className={`badge badge-primary`}
                                css={css`
                                    width: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: flex !important;
                                    justify-content: space-between;
                                `}
                            >
                                <div
                                    css={css`
                                        text-transform: capitalize;
                                        font-size: 12px; !important
                                    `}
                                    className={`badge ${shiftGroupClass}`}
                                >
                                    {visitIndex + 1}. {shiftGroupType} Shifts
                                </div>
                                <div>Completed At: {completedAt}</div>
                            </div>

                            {visitShifts?.shifts
                                ?.filter(({ type }) => Object.keys(shiftTypes).includes(type))
                                ?.map((data, shiftIndex) => {
                                    return (
                                        <div
                                            key={data.id}
                                            css={css`
                                                display: ${data.id.startsWith('REMOVE_SHIFT') ? 'none' : 'auto'};
                                                position: relative;
                                                margin-top: 20px;
                                            `}
                                        >
                                            {mode !== 'view' && (
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        justify-content: flex-end;
                                                        position: absolute;
                                                        right: 0;
                                                    `}
                                                >
                                                    <MdCancel
                                                        css={css`
                                                            cursor: pointer;
                                                        `}
                                                        color="brown"
                                                        size="28px"
                                                        onClick={() => {
                                                            setShifts((prev) => {
                                                                if (data.id.startsWith('NEW_SHIFT')) {
                                                                    return prev.filter(({ id }) => id !== data.id);
                                                                }
                                                                return prev.map(({ id, ...rest }) => ({
                                                                    id: id === data.id ? `REMOVE_SHIFT_${id}` : id,
                                                                    ...rest,
                                                                }));
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <ShiftForm
                                                index={data.id}
                                                data={data}
                                                isBusy={isBusy}
                                                workLocations={workLocations}
                                                shiftIndex={shiftIndex}
                                            />
                                        </div>
                                    );
                                })}
                            {/* Add Shift */}
                            {mode !== 'view' && (
                                <div
                                    css={css`
                                        display: flex;
                                        justify-content: space-between;
                                        padding: 12px;
                                        // border: 1px solid #eee;
                                    `}
                                >
                                    <Button
                                        type="button"
                                        css={css`
                                            background: #17a2b8;
                                        `}
                                        onClick={() => {
                                            setShifts((prev) => [
                                                ...prev,
                                                {
                                                    id: getRandomId(),
                                                    type: 'hourly',
                                                    custom_name: visitShifts?.shifts[0]?.custom_name,
                                                    time: visitShifts?.shifts[0]?.time,
                                                },
                                            ]);
                                        }}
                                        disabled={isBusy || disableAddShift}
                                    >
                                        Add Shift
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                })}

            {/* Confirmation */}
            {mode !== 'view' && (
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        padding: 12px;
                    `}
                >
                    <Button type="submit" disabled={isBusy || !shifts?.length}>
                        {{ edit: 'Update Check', create: 'Create Check', clone: 'Clone Check' }[mode]}
                    </Button>
                </div>
            )}
        </Form>
    );
}

function getFormLabel(name) {
    const [initial, ...other] = (name || 'Unnamed Field').replaceAll('_', ' ').split('');
    return initial.toUpperCase() + other.join('').replaceAll(/([a-z])([A-Z])/g, '$1 $2');
}

function ShiftForm({ data, index, isBusy, workLocations, shiftIndex }) {
    const [selectedType, setSelectedType] = useState(data?.type || 'hourly');
    const [_workLocation, _setWorkLocation] = useState(
        data?.workLocationID
            ? data.workLocationID
            : (workLocations || [])?.find((workLocation) => workLocation?.work_site_id === data?.work_site_id)
                  ?.workLocationID
    );
    const handleChangeWorkLocation = (e) => {
        _setWorkLocation(e.target.value);
    };

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px;
                border: 1px solid #ddd;
            `}
        >
            <span
                className="badge badge-info mr-2"
                css={css`
                    max-width: fit-content;
                    position: absolute;
                    top: 0;
                    left: 0;
                `}
            >
                Shift {shiftIndex + 1}
            </span>
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    padding: 8px 0;
                `}
            >
                <Form.Group
                    css={css`
                        width: 33%;
                    `}
                >
                    <Form.Label>Shift Completion Time</Form.Label>
                    <Form.Control
                        type="datetime-local"
                        name={`shift-${index}-time`}
                        disabled={isBusy}
                        defaultValue={formatDateToLocalISO(data?.time || new Date()).slice(0, 16)}
                    />
                </Form.Group>
                <Form.Group
                    css={css`
                        width: 162px;
                    `}
                >
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                        as="select"
                        name={`shift-${index}-type`}
                        disabled={isBusy}
                        value={selectedType}
                        onChange={(event) => {
                            setSelectedType(event.target.value);
                        }}
                    >
                        {Object.keys(shiftTypes).map((option) => (
                            <option value={option} key={option}>
                                {getFormLabel(option)}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group
                    css={css`
                        width: 40%;
                    `}
                >
                    {/* Metadata */}
                    <Form.Control
                        hidden
                        readOnly
                        value={
                            typeof data?.metadata === 'string' ? data?.metadata : JSON.stringify(data?.metadata || {})
                        }
                        name={`shift-${index}-metadata`}
                    />
                    <Form.Label>Custom Name</Form.Label>
                    <Form.Control
                        name={`shift-${index}-${selectedType === 'reimbursement' ? 'reimbursementcustom_name' : 'custom_name'}`}
                        defaultValue={
                            data
                                ? data[selectedType === 'reimbursement' ? 'reimbursementcustom_name' : 'custom_name']
                                : ''
                        }
                        readOnly={!!(data.custom_name || data.reimbursementcustom_name)}
                        required={true}
                        // hidden={true}
                    />
                </Form.Group>
            </div>
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    justify-content: space-between;
                `}
            >
                {shiftTypes[selectedType]?.map((field) => (
                    <Form.Group
                        key={field}
                        css={css`
                            width: 160px;
                        `}
                    >
                        <Form.Label>{getFormLabel(field)}</Form.Label>
                        <Form.Control
                            type="number"
                            name={`shift-${index}-${field}`}
                            disabled={isBusy}
                            defaultValue={data ? data[field] : ''}
                            step={0.01}
                            onWheel={(event) => event.currentTarget.blur()}
                        />
                    </Form.Group>
                ))}
                <Form.Group
                    css={css`
                        width: 80%;
                    `}
                >
                    <Form.Label>Work Location</Form.Label>
                    <Form.Control
                        as="select"
                        name={`shift-${index}-workLocationID`}
                        disabled={isBusy}
                        defaultValue={_workLocation}
                        onChange={handleChangeWorkLocation}
                    >
                        {(workLocations || [])?.map((workLocation) => (
                            <option value={workLocation.workLocationID} key={workLocation.workLocationID}>
                                {workLocation.street1} {workLocation.street2 || ''} {workLocation.city}{' '}
                                {workLocation.state} {workLocation.zip} ({workLocation.name})
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>
        </div>
    );
}
