import ID from '../../../_helpers/uuid';

export const evv = () => {
    return {
        defaultVisitBackupFormElements: [
            {
                data: [],
                id: ID.uuid(),
                key: 'Header',
                fieldName: 'EVV Visit Backup Form',
                type: 'textbox',
                instruction: '#Note: This form should be submitted by Employee only, on Client Channel',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'DateTimePicker',
                fieldName: 'Start of Service',
                type: 'datetime',
                instruction: '',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'DateTimePicker',
                fieldName: 'End of Service',
                type: 'datetime',
                instruction: '',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Service Code',
                type: 'textbox',
                instruction: 'Manually Enter Service Code',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Summary of services',
                type: 'textbox',
                instruction: '',
            },
            {
                fieldName: 'Signature',
                id: ID.uuid(),
                key: 'Signature',
                type: 'signature',
                required: true,
            },
            {
                fieldName: 'Signature',
                id: ID.uuid(),
                key: 'Signature',
                type: 'signature',
                required: true,
            },
            {
                id: ID.uuid(),
                key: 'Select',
                fieldName: 'Reason For Backup Visit Form',
                type: 'select',
                data: [
                    {
                        text: 'Participant Refusal',
                    },
                    {
                        text: 'Participant Unavailable',
                    },
                    {
                        text: 'Direct Care Worker Error',
                    },
                    {
                        text: 'Mobile Device Issue',
                    },
                    {
                        text: 'System Related Issue',
                    },
                    {
                        text: 'Telephony Issue',
                    },
                    {
                        text: 'Connection Issue',
                    },
                    {
                        text: 'Service Rendered Outside the Home',
                    },
                    {
                        text: 'Billing System Limitation',
                    },
                ],
                instruction: '',
                required: true,
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Questions/Comments/Concerns',
                type: 'textbox',
                instruction: 'Optional',
            },
        ],
    };
};
