import { Button, Modal } from 'react-bootstrap';
import { css } from 'twin.macro';
import { Fragment, useState } from 'react';
import { fetchWrapper } from '../../../../../../_helpers';
import { toast } from 'react-toastify';
import ZealContractorPaymentForm from '../PaymentForm/ZealContractorPaymentForm';
import SearchByUserEmployee from '../../../EvvSearchModal/SearchByUserEmployee';

export default function ContractorPaymentModal({
    provider,
    contractorPayment = {},
    show,
    handleClose = () => {},
    mode = 'view',
    teamId,
    handleUpdatePayments = () => {},
}) {
    const [isBusy, setIsBusy] = useState(false);
    const [userEmployee, setUserEmployee] = useState();
    const isContractorOnboarded =
        userEmployee?.payroll?.metadata?.onboarded && userEmployee?.payroll?.metadata?.contractorID;
    const handleUserEmployeeSelect = (selectedUser) => {
        setUserEmployee(selectedUser);
    };
    const shouldShowEmployeeMessage = !contractorPayment?.contractorPaymentID && !userEmployee;
    const isContractorOnboardedMessage = userEmployee && !isContractorOnboarded;
    async function onSubmit(paymentData) {
        try {
            if (mode === 'view') return;
            setIsBusy(true);

            const companyID =
                mode === 'edit' ? contractorPayment.companyID : userEmployee?.provider?.payroll?.metadata?.companyID;
            if (!companyID) {
                throw new Error('Company ID is missing');
            }
            const idRouteParam =
                mode === 'edit' ? contractorPayment.contractorPaymentID : userEmployee?.payroll?.metadata?.contractorID;

            if (!idRouteParam) {
                throw new Error(
                    mode === 'edit'
                        ? 'Employee(Contractor) Payment ID is missing'
                        : 'Employee(Contractor) ID is missing'
                );
            }
            const response = await fetchWrapper[{ edit: 'put', create: 'post' }[mode]](
                `/evv/payroll/zeal/contractor/${companyID}/${idRouteParam}/payments${teamId ? `?team=${teamId}` : ''}`,
                paymentData
            );
            toast.success(`Payment ${{ edit: 'edited', create: 'created' }[mode]} successfully!`);
            if (mode === 'edit') {
                handleUpdatePayments(response);
            }
            handleClose();
        } catch (error) {
            toast.error(error.message || `Couldn't ${mode} Payment!`);
        } finally {
            setIsBusy(false);
        }
    }

    return (
        <Fragment>
            <Modal show={Boolean(show)} onHide={handleClose} centered scrollable backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title
                        css={css`
                            width: 100%;
                            padding: 0 12px;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                            `}
                        >
                            <p
                                css={css`
                                    text-transform: capitalize;
                                    padding: 0;
                                    margin: 0;
                                    font-size: larger;
                                `}
                            >
                                {mode} Employee (Contractor) Payment
                            </p>
                            <div
                                css={css`
                                    display: flex;
                                    gap: 12px;
                                    align-items: flex-end;
                                `}
                            >
                                <p
                                    css={css`
                                        font-size: small;
                                        padding: 0;
                                        margin: 0;
                                    `}
                                >
                                    {contractorPayment.contractorPaymentID}
                                </p>
                                <span
                                    className={`badge badge-sm ${contractorPayment?.status === 'pending' ? 'badge-warning' : contractorPayment?.status === 'processed' ? 'badge-success' : contractorPayment?.status === 'failed' ? 'badge-danger' : 'badge-info'}`}
                                >
                                    {contractorPayment?.status}
                                </span>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '80vh' }}>
                    {contractorPayment?.contractorID ? (
                        <div className="mb-3">
                            <span className="badge badge-info mr-2">{contractorPayment?.contractorID}</span>
                            {`${contractorPayment?.first_name} ${contractorPayment?.last_name}`}{' '}
                        </div>
                    ) : (
                        <SearchByUserEmployee
                            provider={provider}
                            employee={userEmployee}
                            handleSelect={handleUserEmployeeSelect}
                            disabled={!provider?.value}
                            team={teamId}
                            zealContractor={true}
                            isClearable={false}
                        />
                    )}
                    {shouldShowEmployeeMessage && (
                        <div
                            className="text-center "
                            css={css`
                                margin-top: 4rem;
                            `}
                        >
                            Please select an Employee (Contractor) !
                        </div>
                    )}
                    {isContractorOnboardedMessage && (
                        <div
                            className="text-center "
                            css={css`
                                margin-top: 4rem;
                            `}
                        >
                            Employee (Contractor) is not onboarded !
                        </div>
                    )}
                    {(contractorPayment?.contractorPaymentID || (isContractorOnboarded && userEmployee)) && (
                        <ZealContractorPaymentForm
                            employee={userEmployee}
                            data={contractorPayment}
                            isBusy={mode === 'view' || isBusy}
                            mode={mode}
                            onSubmit={onSubmit}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
