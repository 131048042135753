import React from 'react';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';

const Select = ({ error, data, isPreview, isDisabled, elementRef }) => (
    <Form.Group controlId="formGridEmail">
        <Form.Label>
            <h5 className="warpIt">
                {data.fieldName}
                <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
            </h5>
        </Form.Label>

        {data.instruction && (
            <pre className="small text-muted warpIt preStyles">
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    {data.instruction}
                </Linkify>
            </pre>
        )}
        <Form.Control
            as="select"
            name={!isPreview ? data.name || `Select-${data._id}` : 'Select'}
            ref={
                elementRef &&
                elementRef({
                    required: data.hasOwnProperty('required') ? data.required : true,
                    ...(data.validation || {}),
                })
            }
            value={data.value}
            defaultValue={data.defaultValue}
            disabled={data.value || data.isDisabled || isDisabled}
        >
            {data.data.length &&
                data.data.map((option) => (
                    <option value={option.value} key={option.value}>
                        {option.text}
                    </option>
                ))}
        </Form.Control>
        <Form.Text className="text-danger">
            {error && error.type === 'required' ? error?.message || 'This field is required' : error?.message}
        </Form.Text>
    </Form.Group>
);

export default Select;
