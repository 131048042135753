import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { css } from 'twin.macro';

function OurAccordion({ title, children, titleTag = 'h4', defaultShow = true, style = {} }) {
    const [isOpen, setIsOpen] = useState(defaultShow);
    return (
        <div style={style}>
            <Accordion defaultActiveKey={defaultShow ? '0' : ''}>
                <Accordion.Toggle
                    as={Card.Header}
                    variant="link"
                    eventKey="0"
                    className="d-flex align-items-center justify-content-between"
                    css={css`
                        cursor: pointer;
                    `}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {titleTag === 'h4' ? <h4>{title}</h4> : <>{title}</>}
                    <div>{isOpen ? <FaCaretUp size="24" /> : <FaCaretDown size="24" />}</div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>{children}</Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
}

export default OurAccordion;
