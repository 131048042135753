// core imports
import { css } from 'twin.macro';
import { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { toast } from 'react-toastify';

//componentss
import ReactSelectComponent from '../../Admin/users/ReactSelectComponent';
import { InviteToTeamContent } from './InviteToTeamContent';

import loaderStyles from '../../../_elements/loader';
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';

import { inviteToRoomValidation, fetchWrapper } from '../../../_helpers';
import { checkPermission } from '../../../_helpers/roles/check-roles';

function AppPeopleModal({
    show,
    handleClose,
    handleShow,
    role,
    roomId,
    channelMembers,
    ui,
    startLoading,
    stopLoading,
    getOtherUserData,
    getChannelMembers,
}) {
    const [userName, setUserName] = useState(null);
    const [emailId, setEmailId] = useState(null);
    const [selectedOption, setSelectedOption] = useState();
    const [addByEmail, setAddByEmail] = useState(false);
    const [step, setStep] = useState('userInfo');
    const [user, setUser] = useState(null);
    const [assignedRole, setAssignedRole] = useState('user');

    const { register, getValues, errors, setError, clearErrors } = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            name: '',
        },
        resolver: yupResolver(inviteToRoomValidation),
    });

    //Get the selected options values in user state
    useEffect(() => {
        if (selectedOption) {
            const fectUserData = async () => {
                let user = await getOtherUserData(selectedOption.value);
                return setUser(user);
            };
            fectUserData();
        }
    }, [selectedOption, getOtherUserData]);

    const assignRoomToUser = async (newUser, chatroomId) => {
        try {
            const response = await fetchWrapper.post(`chatrooms/${chatroomId}/invite`, newUser);
            toast.success(response.message);
            getChannelMembers(chatroomId);

            stopLoading();
            handleClose();
        } catch (e) {
            stopLoading();
            toast.error('Error while inviting');
        }
    };

    const handleAdd = () => {
        startLoading();

        if (user) {
            const tempUser = {
                name: user.name,
                email: user.email,
                role: assignedRole,
            };
            assignRoomToUser(tempUser, roomId);
        } else if (userName && emailId) {
            const tempData = {
                name: userName,
                email: emailId,
                role: assignedRole,
            };
            assignRoomToUser(tempData, roomId);
        } else {
            stopLoading();
            console.log('no data no user', userName, emailId);
            setError('name', {
                type: 'manual',
                message: 'Name is required',
            });
        }
    };
    const handleBack = () => {
        setStep('userInfo');
        setUser(null);
    };

    const handleNext = async () => {
        const email = getValues('email');
        const name = getValues('name');
        if (user) {
            const memberIdx = channelMembers.findIndex((member) => member.email === user.email);
            const isPresent = Boolean(memberIdx >= 0);
            if (isPresent) {
                toast.error('User is already in room');
                stopLoading();
                return setError('email', {
                    type: 'manual',
                    message: 'User is already in the room',
                });
            } else {
                stopLoading();
                return setStep('roleInfo');
            }
        } else if (email) {
            const memberIdx = channelMembers.findIndex((member) => member.email === email);
            const isPresent = Boolean(memberIdx >= 0);
            if (isPresent) {
                toast.error('User is already in room');
                stopLoading();
                return setError('email', {
                    type: 'manual',
                    message: 'User is already in the room',
                });
            } else {
                stopLoading();
                setEmailId(email);
                setUserName(name);
                return setStep('roleInfo');
            }
        } else {
            stopLoading();
            return setError('email', {
                type: 'manual',
                message: 'Email is required',
            });
        }
    };
    const handleRoleChange = (e) => {
        setAssignedRole(e.target.value);
    };
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="modal-headers">Add People To Channel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {ui.loading && (
                    <div css={loaderStyles}>
                        <img src="/images/loading.gif" alt="loader" />
                    </div>
                )}
                {step === 'userInfo' ? (
                    <Fragment>
                        {addByEmail ? (
                            <Fragment>
                                <Form.Group>
                                    <Form.Label css={formLabel}>Email address</Form.Label>
                                    <Form.Control
                                        css={formInput}
                                        type="email"
                                        isInvalid={errors.email}
                                        name="email"
                                        ref={register}
                                        placeholder="Enter email address"
                                    />
                                    <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label css={formLabel}>Name</Form.Label>
                                    <Form.Control
                                        css={formInput}
                                        type="text"
                                        isInvalid={errors.name}
                                        onChange={() => clearErrors('name')}
                                        placeholder="Enter name"
                                        name="name"
                                        ref={register}
                                    />
                                    {errors.firstName && 'First name is required'}
                                    <Form.Text className="text-danger">{errors.name?.message}</Form.Text>
                                </Form.Group>
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    Add by{' '}
                                    <button
                                        className="btn btn-link "
                                        css={css`
                                            font-size: 1.8rem;
                                            padding: 0;
                                        `}
                                        onClick={() => {
                                            setEmailId(null);
                                            setUserName(null);
                                            setAddByEmail(false);
                                        }}
                                    >
                                        Name
                                    </button>
                                    ?
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label css={formLabel}>Name</Form.Label>
                                    <ReactSelectComponent
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        placeholder="Search user"
                                        assignedTo={selectedOption && selectedOption.value}
                                    />
                                </Form.Group>
                                <div
                                    css={css`
                                        font-size: 1.5vmin;
                                        padding-top: 60px;
                                    `}
                                >
                                    User not in the system? Add by{' '}
                                    <button
                                        className="btn btn-link "
                                        onClick={() => {
                                            setAddByEmail(true);
                                            setUser(null);
                                        }}
                                    >
                                        Email
                                    </button>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {console.log(role, 'ROLE')}
                        {console.log(checkPermission(role, 'assign:channalRole'))}
                        {
                            // Show Roles setting for specific roled users only
                            checkPermission(role, 'assign:channalRole') && (
                                <div className="mb-4">
                                    <h2>Role</h2>

                                    <div onChange={handleRoleChange}>
                                        <label className="mx-2">
                                            <input type="radio" value="user" name="role" defaultChecked /> User
                                        </label>
                                        <label className="mx-2">
                                            <input type="radio" value="supervisor" name="role" /> Supervisor
                                        </label>
                                        <label className="mx-2">
                                            <input type="radio" value="doctor" name="role" /> Third Party
                                        </label>
                                        <label className="mx-2">
                                            <input type="radio" value="family" name="role" /> Family
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                        {user ? (
                            <InviteToTeamContent teamId={null} setShow={handleShow} user={user} />
                        ) : (
                            <Fragment>
                                <Form.Group>
                                    <Form.Label css={formLabel}>Email address</Form.Label>
                                    <Form.Control
                                        css={formInput}
                                        type="email"
                                        defaultValue={getValues('email')}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label css={formLabel}>Name</Form.Label>
                                    <Form.Control
                                        css={formInput}
                                        type="text"
                                        placeholder="Enter name"
                                        defaultValue={getValues('name')}
                                        disabled
                                    />
                                </Form.Group>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                {step === 'userInfo' ? (
                    <Fragment>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleNext}>
                            Next
                        </Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button variant="secondary" onClick={handleBack}>
                            Back
                        </Button>
                        <Button variant="primary" onClick={handleAdd}>
                            Add
                        </Button>
                    </Fragment>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default AppPeopleModal;
