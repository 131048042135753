import { css } from 'twin.macro';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';
import { AiOutlineMinusCircle } from 'react-icons/ai';

const DateTimeRangePicker = ({ data, elementRef, isPreview, error }) => {
    const today = dayjs().format('YYYY-MM-DD');
    const defaultStartTime = '09:00';
    const defaultEndTime = '17:00';

    const parseValue = () => {
        if (!data.value) {
            return [
                {
                    startDateTime: dayjs(`${today} ${defaultStartTime}`).toISOString(),
                    endDateTime: dayjs(`${today} ${defaultEndTime}`).toISOString(),
                },
            ];
        }
        try {
            const parsed = JSON.parse(data.value) || [];
            return parsed.map((range) => ({
                startDateTime: dayjs(range.startDateTime).toISOString(),
                endDateTime: dayjs(range.endDateTime).toISOString(),
            }));
        } catch {
            return [];
        }
    };

    const [dateRanges, setDateRanges] = useState(parseValue);

    useEffect(() => {
        setDateRanges(parseValue);
    }, [data.value]);

    const handleDateChange = (index, date) => {
        if (date) {
            const newDateRanges = [...dateRanges];
            const { startDateTime, endDateTime } = newDateRanges[index];
            newDateRanges[index].startDateTime = dayjs(`${date}T${dayjs(startDateTime).format('HH:mm')}`).toISOString();
            newDateRanges[index].endDateTime = dayjs(`${date}T${dayjs(endDateTime).format('HH:mm')}`).toISOString();
            setDateRanges(newDateRanges);
        }
    };

    const handleTimeChange = (index, key, value) => {
        const newDateRanges = [...dateRanges];
        const date = dayjs(newDateRanges[index][key]).format('YYYY-MM-DD');
        newDateRanges[index][key] = dayjs(`${date}T${value}`).toISOString();
        setDateRanges(newDateRanges);
    };

    const addDateRange = () => {
        const lastRange = dateRanges[dateRanges.length - 1];
        const lastEndDate = dayjs(lastRange.endDateTime);
        const newStartDate = lastEndDate.add(1, 'day');

        setDateRanges([
            ...dateRanges,
            {
                startDateTime: newStartDate.set('hour', 9).set('minute', 0).toISOString(),
                endDateTime: newStartDate.set('hour', 17).set('minute', 0).toISOString(),
            },
        ]);
    };

    const removeDateRange = (index) => {
        const newDateRanges = dateRanges.filter((_, i) => i !== index);
        setDateRanges(newDateRanges);
    };

    const calculateHours = (startDateTime, endDateTime) => {
        const start = dayjs(startDateTime);
        const end = dayjs(endDateTime);

        const diffMs = end.diff(start);

        if (diffMs < 0) {
            return '<p style="color:red;font-size:1.5rem;">End time cannot be before start time.</p>';
        }

        const hours = Math.floor(diffMs / 3600000);
        const minutes = Math.ceil((diffMs % 3600000) / 60000);

        const hoursStr = hours > 1 ? `${hours} hours` : `${hours} hour`;
        const minutesStr = minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;

        return `${hoursStr} ${minutesStr}`;
    };

    return (
        <Form.Group
            controlId="formGridDateTimeRange"
            css={css`
                width: 100%;
            `}
        >
            <Form.Label>
                <h5 className="warpIt">
                    {data.fieldName}
                    <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                </h5>
            </Form.Label>
            {data.instruction && (
                <pre className="small text-muted warpIt preStyles">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="_blank" href={decoratedHref} key={key} rel="noreferrer">
                                {decoratedText}
                            </a>
                        )}
                    >
                        {data.instruction}
                    </Linkify>
                </pre>
            )}
            <Row className="align-items-center mb-3">
                <Col sm={3}>Date</Col>
                <Col sm={2}>Start Time</Col>
                <Col sm={2}>End Time</Col>
                <Col></Col>
                <Col sm={2}></Col>
            </Row>
            {dateRanges.map((range, index) => (
                <Row key={index} className="align-items-center mb-3">
                    <Col sm={3}>
                        <input
                            type="date"
                            value={dayjs(range.startDateTime).format('YYYY-MM-DD')}
                            onChange={(e) => handleDateChange(index, e.target.value)}
                            disabled={isPreview}
                            className="form-control"
                            required={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <input
                            type="time"
                            value={dayjs(range.startDateTime).format('HH:mm')}
                            onChange={(e) => handleTimeChange(index, 'startDateTime', e.target.value)}
                            disabled={isPreview}
                            className="form-control"
                        />
                    </Col>
                    <Col sm={2}>
                        <input
                            type="time"
                            value={dayjs(range.endDateTime).format('HH:mm')}
                            onChange={(e) => handleTimeChange(index, 'endDateTime', e.target.value)}
                            disabled={isPreview}
                            className="form-control"
                        />
                    </Col>
                    <Col>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: calculateHours(range.startDateTime, range.endDateTime),
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        {!isPreview && dateRanges.length > 1 && (
                            <button
                                className="btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    removeDateRange(index);
                                }}
                            >
                                <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                            </button>
                        )}
                    </Col>
                </Row>
            ))}
            {!isPreview && (
                <Button variant="primary" onClick={addDateRange}>
                    + Add Date
                </Button>
            )}
            <Form.Control
                type="hidden"
                name={`${!isPreview ? `DateTimeRangePicker-${data._id}` : 'DateTimeRangePicker'}`}
                ref={
                    elementRef &&
                    elementRef({
                        required: data.hasOwnProperty('required') ? data.required : true,
                    })
                }
                value={JSON.stringify(dateRanges)}
                readOnly
            />
            <Form.Text className="text-danger">
                {error && error.type === 'required' && 'This field is required'}
            </Form.Text>
        </Form.Group>
    );
};

export default DateTimeRangePicker;
