import React, { Fragment, useEffect, useState } from 'react';
import { fetchWrapper } from '../../../../../../_helpers';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import ReactSelect from 'react-select';
import EmployeeCheckModal from '../EmployeeCheckModal';
import EmployeeCheckRow from '../EmployeeCheckRow';
import { VscLoading } from 'react-icons/vsc';
import Swal from 'sweetalert2';

function ZealEmployeePayroll({ employee, teamId, workLocations }) {
    const employeeID = employee?.data?.basic?.employeeID || employee?.metadata?.employeeID;
    const companyID = employee?.data?.basic?.companyID;

    const [status, setStatus] = useState(null);

    const [employeeChecks, setEmployeeChecks] = useState([]);
    const [isBusy, setIsBusy] = useState(false);

    const [showEmployeeCheck, setShowEmployeeCheck] = useState({});

    async function fetchEmployeeChecks(companyID, employeeID, status) {
        try {
            setIsBusy(true);
            const response = await fetchWrapper.get(
                `/evv/payroll/zeal/employee/${companyID}/${employeeID}/checks?${status ? `status=${status}` : ''}${teamId ? `&team=${teamId}` : ''}`
            );
            setEmployeeChecks(response);
        } catch (error) {
            if (error.name === 'AbortError') return;
            toast.error("Couldn't fetch employee checks");
        } finally {
            setIsBusy(false);
        }
    }

    async function handleDeleteCheck(checkData) {
        if (isBusy) {
            return;
        }
        try {
            setIsBusy(true);
            if (!companyID) {
                throw new Error('Company ID is missing');
            }
            if (!checkData?.employeeCheckID) {
                throw new Error('Employee Check ID is missing');
            }
            const response = await Swal.fire(`You are deleting following check:
                 <br/> <p style="font-size: 14px;">${checkData.first_name} ${checkData.last_name} ${checkData.employeeCheckID}</p>
                 `);
            if (!response.value) {
                return;
            }
            await fetchWrapper.delete(
                `/evv/payroll/zeal/employee/${companyID}/${checkData.employeeCheckID}/checks${teamId ? `?team=${teamId}` : ''}`
            );
            toast.success(`Check deleted successfully!`);
            setEmployeeChecks((prev) =>
                prev.filter(({ employeeCheckID }) => employeeCheckID !== checkData?.employeeCheckID)
            );
        } catch (error) {
            toast.error(error.message || `Couldn't delete check!`);
        } finally {
            setIsBusy(false);
        }
    }

    async function handleApproveCheck(checkData) {
        if (isBusy) {
            return;
        }
        try {
            setIsBusy(true);
            if (!companyID) {
                throw new Error('Company ID is missing');
            }
            if (!checkData?.employeeCheckID) {
                throw new Error('Employee Check ID is missing');
            }
            const response = await Swal.fire(`You are approving following check:
                 <br/> <p style="font-size: 14px;">${checkData.first_name} ${checkData.last_name} ${checkData.employeeCheckID}</p>
                 `);
            if (!response.value) {
                return;
            }
            await fetchWrapper.patch(
                `/evv/payroll/zeal/employee/${companyID}/${checkData.employeeCheckID}/checks${teamId ? `?team=${teamId}` : ''}`
            );
            toast.success(`Check approved successfully!`);
            setEmployeeChecks((prev) =>
                prev.map((check) =>
                    check.employeeCheckID === checkData.employeeCheckID ? { ...check, approved: true } : check
                )
            );
        } catch (error) {
            toast.error(error.message || `Couldn't delete check!`);
        } finally {
            setIsBusy(false);
        }
    }

    useEffect(() => {
        if (showEmployeeCheck?.mode === 'delete' && showEmployeeCheck?.data?.employeeCheckID) {
            handleDeleteCheck(showEmployeeCheck.data);
        }
        if (showEmployeeCheck?.mode === 'approve' && showEmployeeCheck?.data?.employeeCheckID) {
            handleApproveCheck(showEmployeeCheck.data);
        }
    }, [showEmployeeCheck]);

    useEffect(() => {
        if (companyID && employeeID) {
            fetchEmployeeChecks(companyID, employeeID, status);
        }
    }, [status, companyID, employeeID]);

    return (
        <div
            className="w-100 d-flex flex-column align-items-center"
            css={css`
                overflow-x: hidden;
                gap: 12px;
                padding: 12px;
            `}
        >
            <div className="d-flex flex-row w-100 align-items-end justify-content-between">
                <span>Employee Checks</span>
                <div
                    css={css`
                        margin-left: auto;
                        display: flex;
                        flex-direction: row;
                        gap: 6px;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    <div>Check Status: </div>
                    <div
                        css={css`
                            width: 256px;
                        `}
                    >
                        <ReactSelect
                            options={[
                                { label: 'Pending', value: 'pending' },
                                { label: 'Pre-Processed', value: 'pre-processed' },
                                { label: 'Processed', value: 'processed' },
                            ]}
                            value={
                                status
                                    ? {
                                          label: {
                                              pending: 'Pending',
                                              'pre-processed': 'Pre-Processed',
                                              processed: 'Processed',
                                          }[status],
                                          value: status,
                                      }
                                    : null
                            }
                            onChange={(option) => {
                                setStatus(option?.value || '');
                            }}
                            isDisabled={isBusy}
                            isClearable
                        />
                    </div>
                </div>
            </div>
            <Fragment>
                {isBusy ? (
                    <VscLoading
                        className="spin"
                        size="24px"
                        css={css`
                            overflow: hidden;
                        `}
                    />
                ) : (
                    <Fragment>
                        {employeeChecks?.length ? (
                            <div
                                css={css`
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 12px;
                                `}
                            >
                                {employeeChecks
                                    .sort((c1, c2) => new Date(c2?.check_date) - new Date(c1?.check_date))
                                    .map((employeeCheck, index) => (
                                        <EmployeeCheckRow
                                            key={index}
                                            employeeCheck={employeeCheck}
                                            setShowEmployeeCheck={(data, mode = 'view') => {
                                                setShowEmployeeCheck({ data, mode });
                                            }}
                                            companyID={companyID}
                                            teamId={teamId}
                                            fromPayroll={true}
                                        />
                                    ))}
                            </div>
                        ) : (
                            <div>No Checks Found</div>
                        )}
                    </Fragment>
                )}
            </Fragment>
            <EmployeeCheckModal
                show={
                    !!showEmployeeCheck?.data?.employeeCheckID &&
                    ['view', 'edit', 'clone'].includes(showEmployeeCheck?.mode)
                }
                handleClose={() => {
                    setShowEmployeeCheck({});
                }}
                companyID={companyID}
                employeeCheck={showEmployeeCheck?.data || {}}
                mode={showEmployeeCheck?.mode || 'view'}
                teamId={teamId}
                workLocations={workLocations}
            />
        </div>
    );
}

export default ZealEmployeePayroll;
