import React from 'react';

//components
import Line from './FormElements/Line';
import Paragraph from './FormElements/Paragraph';
import Select from './FormElements/Select';
import DynamicSelect from './FormElements/DynamicSelect';
import Checkbox from './FormElements/Checkbox';
import Radio from './FormElements/Radio';
import DateComponent from './FormElements/DateComponent';
import TimeComponent from './FormElements/TimeComponent';
import DateAndTimeComponent from './FormElements/DateAndTimeComponent';
import FullName from './FormElements/FullName';
import Email from './FormElements/Email';
import Header from './FormElements/Header';
import SignatureFile from './FormElements/SignatureFile';
import File from './FormElements/File';
import AudioFile from './FormElements/AudioFile';
import Image from './FormElements/Image';
import InputField from './FormElements/InputField';
import Link from './FormElements/Link';
import Photo from './FormElements/Photo';
import PdfPreviewer from './FormElements/PdfPreviewer';
import DateTimeRangePicker from './FormElements/DateTimeRangePicker';

const FormElement = ({ form, elementRef, isPreview, errors }) => {
    switch (form.key) {
        case 'Header':
            return <Header elementRef={elementRef} data={form} isPreview={isPreview} />;
        case 'FullName':
            return (
                <FullName
                    elementRef={elementRef}
                    data={form}
                    isPreview={isPreview}
                    error={errors && errors[`FullName-${form._id}`]}
                />
            );
        case 'Email':
            return (
                <Email
                    elementRef={elementRef}
                    data={form}
                    isPreview={isPreview}
                    error={errors && errors[`Email-${form._id}`]}
                />
            );
        case 'InputField':
            return (
                <InputField
                    elementRef={elementRef}
                    data={form}
                    isPreview={isPreview}
                    error={errors && errors[`InputField-${form._id}`]}
                />
            );
        case 'Divider':
            return <Line data={form} isPreview={isPreview} />;
        case 'Paragraph':
            return <Paragraph data={form} isPreview={isPreview} />;
        // case 'Address':
        // 	return <Address data={form} isPreview={isPreview} />;
        case 'Select':
            return <Select data={form} elementRef={elementRef} error={errors && errors[`Select-${form._id}`]} />;
        case 'AccrualPolicySelect':
            return (
                <DynamicSelect
                    fetchUrl="evv/payroll/zeal/employees/accrual-policies?submitted=false&team={{_id}}"
                    mapOptions={(results) =>
                        (results?.accrualPolicies || []).map((policy) => {
                            const text = `${policy?.policy_code} - ${policy?.policy_name} [${policy?.policy_status}]`;
                            return {
                                text,
                                label: text,
                                // textLabel field is required in dynamic select value
                                value: JSON.stringify({ ...policy, textLabel: text }),
                                id: policy?.policy_code,
                            };
                        })
                    }
                    data={form}
                    elementRef={elementRef}
                    error={errors && errors[`Select-${form._id}`]}
                    isPreview={isPreview}
                />
            );
        case 'ZealEmployeeWorkLocationSelect':
            return (
                <DynamicSelect
                    fetchUrl="/evv/payroll/zeal/provider/{{provider.payroll.metadata.companyID}}/work-locations?team={{_id}}"
                    mapOptions={(results) =>
                        (results || []).map((workLocation) => {
                            const text = workLocation?.name;
                            return {
                                text,
                                label: text,
                                // textLabel field is required in dynamic select value
                                value: workLocation?.workLocationID,
                                id: workLocation?.workLocationID,
                            };
                        })
                    }
                    data={form}
                    elementRef={elementRef}
                    error={errors && errors[`Select-${form._id}`]}
                    isPreview={isPreview}
                />
            );
        case 'Checkbox':
            return (
                <Checkbox
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`Checkbox-${form._id}`]}
                />
            );
        case 'Radio':
        case 'ServiceRadio':
            return (
                <Radio
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`Radio-${form._id}`]}
                />
            );
        // case 'TextInput':
        // 	return <ShortText />;
        // case 'TextArea':
        // 	return <TextArea />;
        case 'DatePicker':
            return (
                <DateComponent
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`DatePicker-${form._id}`]}
                />
            );
        case 'TimePicker':
            return (
                <TimeComponent
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`DatePicker-${form._id}`]}
                />
            );
        case 'DateTimePicker':
            return (
                <DateAndTimeComponent
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`DateTimePicker-${form._id}`]}
                />
            );
        case 'Image':
            return (
                <Image
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`Image-${form._id}`]}
                />
            );
        case 'File':
            return (
                <File
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`File-${form._id}`]}
                />
            );
        case 'AudioFile':
            return (
                <AudioFile
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`AudioFile-${form._id}`]}
                />
            );
        case 'Signature':
            return (
                <SignatureFile
                    data={form}
                    isPreview={isPreview}
                    elementRef={elementRef}
                    error={errors && errors[`Signature-${form._id}`]}
                />
            );
        case 'Link':
            return <Link data={form} isPreview={isPreview} elementRef={elementRef} />;
        case 'Photo':
            return <Photo data={form} isPreview={isPreview} elementRef={elementRef} />;
        case 'PdfPreviewer':
            return <PdfPreviewer data={form} isPreview={isPreview} elementRef={elementRef} />;
        case 'DateTimeRangePicker':
            return <DateTimeRangePicker data={form} isPreview={isPreview} elementRef={elementRef} />;
        default:
            return null;
    }
};

export default FormElement;
