import React, { useState, useEffect, useCallback } from 'react';
import { css } from 'twin.macro';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { getTeamIdFromURL, getChannelIdFromSettingsURL } from '../../../_helpers';
import { ContentContainerStyles, EVVContainerStyles } from './styles/EVVContainerStyles';
import { SET_EVVSTATE } from '../../../_redux/actions/ActionTypes';
import PayAllocation from './Payroll/PayAllocation';
import Payrolls from './Payroll/Payrolls';
import { getRole } from '../../../_helpers/roles/get-roles';
import Reports from './Payroll/Reports';
import PTO from './PTO';
import Reimbursement from './Visit/UserVisit';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const UserPayrollContainer = ({ channelDetails, currUser, teams }) => {
    const teamId = getTeamIdFromURL();
    const isTeamSettings = !!useLocation().pathname.match(/user-payroll/);
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    const [selectedPage, setSelectedPage] = useState('PTO');
    const [canViewPayroll, setCanViewPayroll] = useState(false);
    const [provider, setProvider] = useState({});
    const dispatch = useDispatch();
    const [isBusy, setIsBusy] = useState(false);

    const Content =
        {
            PayAllocation,
            Payrolls,
            Reports,
            PTO,
            Reimbursement,
        }[selectedPage] || (() => <div>No Content</div>);

    useEffect(() => {
        const getEvvStateData = async () => {
            try {
                setIsBusy(true);
                const response = await fetchWrapper.get(`/evv/state?${teamId ? `&team=${teamId}` : ''}`);
                if (response?.state) {
                    dispatch({
                        type: SET_EVVSTATE,
                        payload: response?.state,
                    });
                }
            } catch (err) {
                console.log(err);
            } finally {
                // fetchProviderData()
            }
        };

        getEvvStateData();
    }, [dispatch]);

    useEffect(() => {
        let team = teams.filter((team) => team.id === teamId);
        let role = getRole(currUser, team[0]);
        if (role === 'admin' || role === 'teamManager' || role === 'owner') {
            setCanViewPayroll(true);
        }
    }, [currUser, teams]);

    const fetchProviderData = async () => {
        if (!isTeamSettings || !teamId) {
            return;
        }

        const team = (teams || []).find((team) => team.id === teamId);

        try {
            const {
                results: [response],
            } = await fetchWrapper.get(`evv/provider?team=${teamId}`);

            setProvider({
                value: response?._id,
                label: response?.agencyName,
                providerId: response?._id,
                teamId: team?.id,
                agencyName: response?.agencyName,
                team: response?._id,
                state: response?.state,
                payroll: response?.payroll,
            });
        } catch (error) {
            if (error.name !== 'AbortError') {
                toast.error("Couldn't fetch provider data");
            }
        } finally {
            setIsBusy(false);
        }
    };

    if (!canViewPayroll && channelIdFromUrl) {
        return null;
    }
    return (
        <EVVContainerStyles>
            <h1 className="mb-4">Payroll</h1>
            <div
                className="d-flex flex-column"
                css={css`
                    min-height: 800px;
                `}
            >
                <Tabs
                    id="controlled-tab-example"
                    activeKey={selectedPage}
                    onSelect={(selectedKey) => setSelectedPage(selectedKey)}
                    className="mb-0"
                >
                    <Tab eventKey="PTO" title="PTO"></Tab>
                    <Tab eventKey="Reimbursement" title="Reimbursement"></Tab>
                    <Tab eventKey="PayAllocation" title="Pay Allocation"></Tab>
                    <Tab eventKey="Payrolls" title="Payrolls"></Tab>
                    <Tab eventKey="Reports" title="Reports"></Tab>
                </Tabs>
                <ContentContainerStyles>
                    {/* {(isBusy || !provider?.providerId) ? (
                        <div>Loading Provider Data...</div>
                    ) : (
                        <Content teamId={teamId} channelDetails={channelDetails} providerInfo={provider} />
                    )} */}
                    <Content teamId={teamId} channelDetails={channelDetails} providerInfo={provider} />
                </ContentContainerStyles>
            </div>
        </EVVContainerStyles>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(UserPayrollContainer);
