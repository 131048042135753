import React, { useState, useEffect, Fragment } from 'react';
import { fetchWrapper } from '../../../_helpers';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import ZealStaffPayroll from '../../Admin/EVVContainer/Payroll/ZealComponents/ViewPayments/ZealStaffPayroll';
import { toast } from 'react-toastify';

const PayrollsModalEmployee = ({ show, setShow, teamId, teams, currUserId }) => {
    const [employee, setEmployee] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleFetchEmployee = async () => {
        try {
            setIsBusy(true);
            const provider = ((teams || []).find(({ id }) => id === teamId) || {})?.provider?._id;
            if (!provider || !currUserId || !teamId) return;
            const response = await fetchWrapper.get(
                `evv/employee?limit=2&provider=${provider}&team=${teamId}&user=${currUserId}`
            );
            if (response?.results?.length === 1) {
                setEmployee(response?.results[0]);
            }
        } catch (error) {
            if (error.name === 'AbortError') return;
            toast.error(error?.message);
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {
        handleFetchEmployee();
    }, [show, teamId]);

    return (
        <Modal size="xl" show={show} centered onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center justify-content-between w-100">
                    <h3> Payrolls </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isBusy ? (
                    <div className="text-center">Loading...</div>
                ) : (
                    <Fragment>
                        {!employee ? (
                            <div>You are not employee in this team</div>
                        ) : (
                            <ZealStaffPayroll employeePayroll={employee?.payroll} teamId={teamId} />
                        )}
                    </Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    clientRooms: state.rooms.clientRooms,
    organizations: state.organizations.organizations,
    currUserId: state.user.user._id,
});

export default connect(mapStateToProps, {})(PayrollsModalEmployee);
