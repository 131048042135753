import { css } from 'twin.macro';
import AsyncSelect from 'react-select/async';

const reimbursementStatusTypes = ['draft', 'approved', 'payment_pending', 'payment_processed'];

const ReimbursementStatus = ({ reimbursementStatus, setReimbursementStatus }) => {
    return (
        <div
            className="search-container"
            css={css`
                .userImage {
                    width: 3rem !important;
                    height: 3rem !important;
                }
                width: 256px;
            `}
        >
            <span className="search-title">Select Status:</span>
            <AsyncSelect
                css={css`
                    text-transform: capitalize;
                `}
                defaultOptions={reimbursementStatusTypes.map((status) => ({
                    value: status,
                    label: status.replace('_', ' '),
                }))}
                onChange={(e) => setReimbursementStatus(e)}
                isClearable={true}
                value={reimbursementStatus?.value ? reimbursementStatus : null}
            />
        </div>
    );
};

export default ReimbursementStatus;
