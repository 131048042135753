import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionSubmissionModal from '../../../../Admin/Tasks/AccordionSubmissionModal';
import { FaWpforms } from 'react-icons/fa';
import { css } from 'twin.macro';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import OurAccordionMultiple from '../../../../../_elements/OurAccordionMultiple';
import StaticPreview from '../../../../Admin/Form/StaticPreview';
import MessageTime from '../../../Common/MessageTime';

const FormTitleDiv = ({ form }) => {
    const submittedAt = form?.createdAt ? new Date(form?.createdAt || 0)?.toString() : '';
    return (
        <div
            className="pointer d-flex"
            css={css`
                align-items: center;
                gap: 6px;
                font-size: 16px;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            `}
        >
            <div
                className="d-flex"
                css={css`
                    align-items: center;
                    gap: 6px;
                `}
            >
                <FaWpforms />
                <span>{form.name}</span>
                <span className="badge badge-info">
                    {
                        {
                            eos: 'end of service',
                            sos: 'start of service',
                            multipleSubmission: 'multiple submission',
                            normal: 'normal',
                            speciality: 'speciality',
                        }[form.type]
                    }
                </span>
            </div>
            <div
                className="d-flex"
                css={css`
                    align-items: center;
                    gap: 6px;
                `}
            >
                <span>
                    {form?.submitted ? (
                        <HiOutlineCheckCircle size={30} className="text-primary cursor-pointer ml-2" />
                    ) : (
                        <HiOutlineExclamationCircle size={30} className="text-secondary cursor-pointer ml-2" />
                    )}
                </span>
                {form?.submitted ? (
                    <span className="px-2 text-right text-muted text-uppercase">
                        <MessageTime dateAndTime={submittedAt} />{' '}
                    </span>
                ) : null}
            </div>
        </div>
    );
};

const VisitTemplateAccordionForms = ({
    forms = [],
    userId,
    currRoom,
    team,
    position,
    checkIsShowing,
    handleCloseAccordionModal = () => {},
    visitTemplateId,
    setActiveFormInMarker = () => {},
}) => {
    const [activeKey, setActiveKey] = useState('9e9');
    return forms?.length ? (
        <div
            css={css`
                border: 2px solid #50aeb0;
            `}
        >
            <Accordion
                defaultActiveKey={activeKey}
                onSelect={(e) => {
                    setActiveKey(e);
                    if ((forms || [])?.length >= e) {
                        setActiveFormInMarker(forms[e]?._id);
                    }
                }}
            >
                {forms?.map((form, formIndex) => (
                    <OurAccordionMultiple
                        title={<FormTitleDiv form={form} />}
                        eventKey={formIndex.toString()}
                        key={form?._id || form?.id || formIndex}
                        isOpen={activeKey === formIndex.toString()}
                        titleTag=""
                    >
                        {activeKey === formIndex.toString() && (
                            <div
                                css={css`
                                    padding: 2px;
                                `}
                            >
                                {form?.submitted || form?.completed ? (
                                    <StaticPreview
                                        key={form?._id || form?.id || formIndex}
                                        formElements={form?.formElements || []}
                                        onlyView={true}
                                    />
                                ) : (
                                    <AccordionSubmissionModal
                                        key={form?._id || form?.id || formIndex}
                                        form={form}
                                        roomId={currRoom && (currRoom?.id || currRoom?._id)}
                                        userId={userId}
                                        currRoom={currRoom}
                                        formType={form?.type}
                                        team={team}
                                        position={position}
                                        checkIsShowing={checkIsShowing}
                                        activeKey={activeKey}
                                        isAccordionForm={true}
                                        handleClose={handleCloseAccordionModal}
                                        visitTemplateId={visitTemplateId}
                                    />
                                )}
                                <hr />
                            </div>
                        )}
                    </OurAccordionMultiple>
                ))}
            </Accordion>
        </div>
    ) : null;
};

export default VisitTemplateAccordionForms;
