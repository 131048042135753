import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';
import { fetchWrapper, getRoomIdFromURL, SafeJSONParse } from '../../../../_helpers';
import { toast } from 'react-toastify';

const getTeamFromTeamRooms = (teamRooms, teams, roomId) =>
    teams?.find(
        ({ _id }) =>
            _id ===
            Object.values(teamRooms).find((teamRoom) => teamRoom?.rooms?.some((room) => room?._id === roomId))?.rooms[0]
                ?.team
    );

const DynamicSelect = ({
    error,
    data,
    isPreview,
    isDisabled,
    elementRef,
    teamRooms,
    teams,
    fetchUrl = '',
    mapOptions = () => [],
}) => {
    const roomId = getRoomIdFromURL();
    const team = getTeamFromTeamRooms(teamRooms, teams, roomId);
    const viewMode = data?.isFetched || data?.value;
    const selectedValue = SafeJSONParse(data?.value || '{}')?.textLabel;
    const [apiError, setApiError] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [loading, setIsloading] = useState(false);
    const fetchData = async () => {
        try {
            setIsloading(true);
            const url = fetchUrl.replace(/\{\{[^\}]+\}\}/g, (v) => {
                const key = v.replace(/[\{\}]+/g, '');
                const subKeys = key.split('.');
                let data = team;
                subKeys.forEach((k) => {
                    data = data[k];
                });
                return data;
            });
            const result = await fetchWrapper.get(url);
            setDataList(mapOptions(result));
        } catch (err) {
            if (err?.name === 'AbortError') return;
            console.log('err');
            const errorMessage = err?.message || 'Something went wrong';
            toast.error(errorMessage);
            setApiError(errorMessage);
        } finally {
            setIsloading(false);
        }
    };
    useEffect(() => {
        if (roomId && !viewMode && !isPreview) {
            fetchData();
        }
    }, [roomId, viewMode]);
    return (
        <Form.Group controlId="formGridEmail">
            <Form.Label>
                <h5 className="warpIt">
                    {data.fieldName}
                    <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                </h5>
            </Form.Label>

            {data.instruction && (
                <pre className="small text-muted warpIt preStyles">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        {data.instruction}
                    </Linkify>
                </pre>
            )}
            {loading ? <span>Loading data ...</span> : null}
            <Form.Control
                as="select"
                name={!isPreview ? data.name || `Select-${data._id}` : 'Select'}
                ref={
                    elementRef &&
                    elementRef({
                        required: data.hasOwnProperty('required') ? data.required : true,
                        ...(data.validation || {}),
                    })
                }
                disabled={data.value || data.isDisabled || isDisabled}
            >
                {(viewMode ? data?.value : dataList?.length) &&
                    (viewMode ? [{ value: selectedValue, text: selectedValue }] : dataList).map((option) => (
                        <option value={option.value} key={option.value}>
                            {option.text}
                        </option>
                    ))}
            </Form.Control>
            <Form.Text className="text-danger">
                {apiError && <p>{apiError}</p>}
                {error && error.type === 'required' ? error?.message || 'This field is required' : error?.message}
            </Form.Text>
        </Form.Group>
    );
};
const mapStateToProps = (state) => ({
    teamRooms: state.rooms.teamRooms,
    teams: state.teams.teams,
});

export default connect(mapStateToProps, {})(DynamicSelect);
