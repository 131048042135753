import React, { useEffect, useState } from 'react';
import { css } from 'twin.macro';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

//costume hooks

// components
import SearchByProvider from '../EvvSearchModal/SearchByProvider';
import SearchByUserEmployee from '../EvvSearchModal/SearchByUserEmployee';
import ZealCompanyPayroll from './ZealComponents/ViewPayments/ZealCompanyPayroll';
import { connect } from 'react-redux';
import { fetchWrapper, getTeamIdFromURL, toastWrapper } from '../../../../_helpers';
import ZealStaffPayroll from './ZealComponents/ViewPayments/ZealStaffPayroll';

const Payrolls = ({ teams }) => {
    const isTeamSettings = !!useLocation().pathname.match(/user-payroll/);
    const [userEmployee, setUserEmployee] = useState();
    const [provider, setProvider] = useState({});

    const handleUserEmployeeSelect = (selectedUser) => {
        setUserEmployee(selectedUser);
    };

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
    };

    const teamId = getTeamIdFromURL();
    useEffect(() => {
        if (!isTeamSettings || !teamId) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        fetchWrapper
            .get(`evv/provider?team=${teamId}`)
            .then(({ results: [response] }) =>
                setProvider({
                    value: response?._id,
                    label: response?.agencyName,
                    providerId: response?._id,
                    teamId: team?.id,
                    agencyName: response?.agencyName,
                    team: response?._id,
                    state: response?.state,
                    payroll: response?.payroll,
                })
            )
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                toastWrapper.error("Couldn't fetch provider data");
            });
    }, [teams, isTeamSettings]);

    return (
        <div>
            <div
                className="d-flex flex-row align-items-end justify-content-between mb-4"
                css={css`
                    gap: 12px;
                `}
            >
                <div
                    className="d-flex flex-row"
                    css={css`
                        gap: 12px;
                    `}
                >
                    <div
                        css={css`
                            min-width: 270px;
                        `}
                    >
                        <SearchByProvider
                            provider={provider}
                            handleSelect={handleProviderSelect}
                            isDisabled={isTeamSettings}
                            team={teamId}
                        />
                    </div>
                    <div
                        css={css`
                            min-width: 270px;
                        `}
                    >
                        <SearchByUserEmployee
                            userEmployee={userEmployee}
                            handleSelect={handleUserEmployeeSelect}
                            provider={provider}
                            team={teamId}
                            disabled={!provider?.value}
                        />
                    </div>
                </div>
                <span
                    className="badge badge-info"
                    css={css`
                        font-size: 16px !important;
                    `}
                >
                    {provider?.payroll?.type}
                </span>
            </div>
            <div
                className="custom-card d-flex flex-col"
                css={css`
                    min-height: 70vh;
                    overflow: auto;
                    position: relative;
                    justify-content: center;
                `}
            >
                {!provider?.value ? (
                    <div>Please select a provider</div>
                ) : (
                    {
                        zeal: (
                            <ZealPayrollView
                                providerPayroll={provider?.payroll}
                                employeePayroll={userEmployee?.payroll}
                                teamId={teamId}
                            />
                        ),
                    }[provider?.payroll?.type] || (
                        <div>Payroll process has not been set up for {provider?.payroll?.type}</div>
                    )
                )}
            </div>
        </div>
    );
};

function ZealPayrollView({ providerPayroll, employeePayroll, teamId }) {
    const isProviderOnboarded = providerPayroll?.metadata?.companyID && providerPayroll?.metadata?.status === 'live';
    const [workLocations, setWorkLocations] = useState([]);
    const fetchWorkLocations = async (_companyID) => {
        try {
            const response = await fetchWrapper.get(
                `/evv/payroll/zeal/provider/${_companyID}/work-locations${teamId ? `?team=${teamId}` : ''}`
            );
            setWorkLocations(response);
        } catch (error) {
            if (error?.name === 'AbortError') return;
            toast.error(error?.message || "Work locations couldn't be fetched");
        }
    };

    useEffect(() => {
        if (providerPayroll?.data) {
            const _companyID = providerPayroll?.metadata?.companyID;
            if (_companyID) {
                fetchWorkLocations(_companyID);
            } else {
                setWorkLocations([]);
            }
        }
    }, [providerPayroll?.data?._id]);

    if (!isProviderOnboarded) {
        return <div>Provider is not onboarded.</div>;
    }

    if (employeePayroll) {
        return <ZealStaffPayroll employeePayroll={employeePayroll} teamId={teamId} workLocations={workLocations} />;
    }

    return <ZealCompanyPayroll provider={providerPayroll} teamId={teamId} workLocations={workLocations} />;
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
});

export default connect(mapStateToProps)(Payrolls);
