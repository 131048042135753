import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { css } from '@emotion/react';

import VisitTemplateAccordionForms from '../../../Features/Teams/TeamSettings/VisitTemplate/VisitTemplateAccordionForms';
import VisitMap from './VisitMap';
import { getFormDataWithValue } from '../../../../_helpers';
import { statusData } from './Label/_colorData';

function VisitFormsModal({ show, visit, handleClose }) {
    const [activeFormInMarker, setActiveFormInMarker] = useState('');
    return (
        <Modal show={Boolean(show)} onHide={handleClose} centered size="xl" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Visit Forms</span>
                    <span
                        className="badge badge-sm badge-info"
                        css={css`
                            background: ${statusData[visit?.status]};
                            color: white;
                            font-size: 12px !important;
                            margin-left: 16px;
                        `}
                    >
                        {visit?.status?.replaceAll('_', ' ') || 'complete'}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'auto', height: '80vh' }}>
                {visit?.forms?.length ? (
                    <>
                        <VisitTemplateAccordionForms
                            forms={visit?.forms.map((form) => ({
                                ...form,
                                formElements: getFormDataWithValue(form?.formData, form?.data),
                                submitted: true,
                            }))}
                            setActiveFormInMarker={setActiveFormInMarker}
                        />
                        <VisitMap
                            visit={visit}
                            activeFormInMarker={activeFormInMarker}
                            mileageData={visit?.extraInfo?.mileageData}
                            handleClose={handleClose}
                        />
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default VisitFormsModal;
