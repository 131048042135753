import { css } from 'twin.macro';
import { useEffect, useState, useCallback, useMemo, Fragment, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
//images and logos
import CreateTeam from './CreateTeam';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import { connect } from 'react-redux';
import { setNewRoom, setEditedRoom, getRoomsByTeam, getHasSeenRooms } from '../../../_redux/actions/ActionMessage';
import {
    postAvatarTeam,
    editATeam,
    deleteATeamById,
    setNewTeam,
    removeTeam,
    removeChannel,
    setTeams,
    removeUserChannel,
    removeUserTeam,
} from '../../../_redux';
import ChannelSearchComponent from './ChannelSearchComponent';
import TeamSearchComponent from './TeamSearchComponent';
import { setUserLocation, getUserData, setLastSeenChannel } from '../../../_redux/actions/ActionUser';
import Team from './Team';
import {
    checkPermission,
    fetchWrapper,
    Permission,
    sortingMethod,
    // isTheUserNearTheAddressess,
    linkChannelToTeams,
    getUniqueObjects,
    getUniqueChannels,
    debounce,
    getTeamIdFromURL,
    getRoomIdFromURL,
    canSeePublicTeams,
} from '../../../_helpers';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';
import { searchInput, teamList, topSection, listWrapper, teamSort } from './styles/TeamListStyles';
import { StyledScrollBarContainerCSS } from '../../../_elements/container';
import { tabStyle } from '../../../_elements/styles';
import ChatListSkeleton from '../Layouts/ChatListSkeleton';

// icons
import { BsThreeDots } from 'react-icons/bs';
import { HiChevronDoubleUp } from 'react-icons/hi';
import { VscLoading } from 'react-icons/vsc';
import { AiOutlineClose } from 'react-icons/ai';
import { clearInvitationForConference, setConferenceData } from '../../../_redux/actions/ActionConference';

function TeamList({
    teams,
    totalTeams,
    setTeams,
    teamId,
    isLoading,
    user,
    match,
    setOpenDetails,
    setNewRoom,
    setEditedRoom,
    setNewTeam,
    removeTeam,
    removeUserTeam,
    removeChannel,
    removeUserChannel,
    getUserData,
    getRoomsByTeam,
    setLastSeenChannel,
    getHasSeenRooms,
    setMobileSidebar,
    mobileSidebar,
    setConferenceData,
    setShowChat,
    clearInvitationForConference,
}) {
    const roomId = match.params.roomId;
    const [roomIndex, setRoomIndex] = useState(roomId ? roomId : '');
    const [collapse, setCollapse] = useState(teamId ? [teamId] : []);
    const [search, setSearch] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const [filteredChannels, setFilteredChannels] = useState([]);
    const [hasMoreFilteredChannels, setHasMoreFilteredChannels] = useState(false);
    const [totalFilteredChannelPages, setTotalFilteredChannelPages] = useState(9e9);

    const [filteredTeams, setFilteredTeams] = useState([]);
    const [hasMoreFilteredTeams, setHasMoreFilteredTeams] = useState(false);
    const [totalFilteredTeamPages, setTotalFilteredTeamPages] = useState(9e9);

    const [hasMore, setHasMore] = useState(teams.length < totalTeams);
    const [totalTeamPages, setTotalTeamPages] = useState(Math.ceil(totalTeams / 100));

    const history = useHistory();
    const ws = useContext(WebSocketContext);
    const [searchType, setSearchType] = useState('Channels');
    const [activeTab, setActiveTab] = useState('Channels');
    const inputRef = useRef();

    const [userTeamList, setUserTeamList] = useState([]);
    const [userChannelList, setUserChannelList] = useState([]);

    const [channelCount, setChannelCount] = useState(0);
    const [teamCount, setTeamCount] = useState(0);

    const [isReduxTeamsLoading, setIsReduxTeamsLoading] = useState(false);
    const [isTeamsLoading, setIsTeamsLoading] = useState(false);
    const [isChannelsLoading, setIsChannelsLoading] = useState(false);

    const handleSearch = () => {
        setSearch(inputRef.current.value);
        if (inputRef.current.value === '') {
            setFilteredChannels(null);
            setFilteredTeams(null);
        }
    };

    useEffect(() => {
        let userTeamList = Object.assign([], user.teamList);
        let tempUserTeamList = [];
        userTeamList.forEach((teamItem) => {
            if (teamItem.team) {
                tempUserTeamList.push({
                    ...teamItem,
                    ...teamItem.team,
                });
            }
        });
        setUserTeamList(tempUserTeamList);
        let userChannelList = Object.assign([], user.roomList);

        let tempUserChannelList = [];
        userChannelList.forEach((channel) => {
            if (channel.room) {
                tempUserChannelList.push({
                    ...channel,
                    ...channel.room,
                });
            }
        });
        setUserChannelList(tempUserChannelList);
    }, [user.roomList, user.teamList]);

    useEffect(() => {
        if (teamId) {
            getUserData();
            setCollapse([teamId]);
            getRoomsByTeam(teamId);
        }
    }, [getRoomsByTeam, getUserData, teamId]);

    useEffect(() => {
        if (ws && ws.socket) {
            ws.socket.on('team', (team) => {
                if (team.event === 'created') {
                    setNewTeam({ ...team.result, id: team.result._id });
                    getUserData();
                } else if (team.event === 'updated') {
                    setNewTeam(team.result);
                    getUserData();
                } else if (team.event === 'deleted') {
                    removeTeam(team.result.id);
                    getUserData();
                } else if (team.event === 'user:removed' && team.result.user === user._id) {
                    removeTeam(team?.result?.team);
                    removeUserTeam(team?.result?.team);
                    const currentChannelId = getRoomIdFromURL();
                    const teamOfCurrentChannel = (user?.roomList || [])?.find(
                        (item) => item?.room?._id === currentChannelId
                    )?.room?.team;
                    if (teamOfCurrentChannel && teamOfCurrentChannel === team?.result?.team) {
                        history.push('/teams');
                    }
                }
            });
        }
        return () => {
            if (ws && ws.socket) {
                ws.socket.off('team');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws]);

    useEffect(() => {
        if (ws && ws.socket) {
            ws.socket.on('room', (room) => {
                if (room.event === 'created') {
                    setNewRoom({
                        ...room.result,
                        _id: room.result.id,
                        isSeen: true,
                    });
                    let newRoom = {
                        ...room.result,
                        role: 'user',
                    };
                    setUserChannelList([...userChannelList, newRoom]);
                } else if (room.event === 'updated') {
                    let newUserChannelList = Object.assign([], userChannelList);
                    newUserChannelList = newUserChannelList.filter((channel) => channel.id !== room.result.id);
                    setUserChannelList([...newUserChannelList, room.result]);
                    setEditedRoom(room.result);
                    // conference, conferenceid will come, end vayo vane 'false'. not comming
                    setConferenceData(room.result.id, room.result.conference, room.result.team);
                    if (room.result.conference === 'false') {
                        clearInvitationForConference();
                    }
                } else if (room.event === 'deleted') {
                    removeChannel(room.result.id);
                } else if (room.event === 'user:removed' && room.result.user === user._id) {
                    removeChannel(room.result.room);
                    removeUserChannel(room.result.room);
                    // setUserChannelList(prev => prev.filter(channelData => channelData._id !== room.result.room))
                    if (getTeamIdFromURL() === room.result.room) {
                        history.push('/teams');
                    }
                }
            });
        }
        return () => {
            if (ws && ws.socket) {
                ws.socket.off('room');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeChannel, ws]);

    const handleRoomClick = useCallback(
        (roomId, type, manager, admin, teamId) => {
            let role;
            if (user.role === 'admin') {
                role = user.role;
            } else if (user?.teamList?.find((userTeam) => userTeam.team?.id === teamId)?.role === 'teamManager') {
                role = 'teamManager';
            } else if (
                user.id === admin ||
                user?.teamList?.find((userTeam) => userTeam.team?.id === teamId)?.role === 'owner'
            ) {
                role = 'owner';
            } else {
                // manager or other
                role = user.role;
            }
            if (type === 'private') {
                // admin, owner, teamManage can join
                if (checkPermission(role, 'join:channal', { user, teamId })) {
                    setRoomIndex(roomId);
                    setLastSeenChannel(roomId);
                    getHasSeenRooms(teamId, roomId);
                    history.push(`/teams/${roomId}`);
                } else {
                    // if user is invited to channel or user is manager and is invited to that team
                    if (
                        (user?.roomList &&
                            user?.roomList?.find((userRoom) => (userRoom.room?.id || userRoom.room?.id) === roomId)) ||
                        (role === 'manager' &&
                            Boolean(
                                user?.teamList?.find((userteam) => (userteam.team?.id || userteam.team?._id) === teamId)
                            ))
                    ) {
                        setRoomIndex(roomId);
                        setLastSeenChannel(roomId);
                        getHasSeenRooms(teamId, roomId);
                        history.push(`/teams/${roomId}`);
                    } else {
                        toast.error('You are unauthorized!');
                    }
                }
            } else if (type === 'gps_enabled') {
                // if (user.rooms.includes(roomId) || manager === user.id || admin === user.id) {
                // 	const room = rooms.find((room) => room.id === roomId);
                // 	setUserLocation();
                // 	if (!position) {
                // 		toast.error('Enable location service');
                // 	} else if (room.addresses.length === 0) {
                // 		toast.error('No location information! Ask admin to add loaction information');
                // 	} else if (isTheUserNearTheAddressess(room.addresses, position, 200)) {
                // 		setRoomIndex(roomId);
                // 		getHasSeenRooms(teamId, roomId);
                // 		history.push(`/teams/${roomId}`);
                // 	} else {
                // 		toast.error('Go to service location');
                // 	}
                // } else {
                // 	toast.error('You are unauthorized!');
                // }

                // Only Admin and owner can access gps channel
                if (user.role === 'admin' || user.id === admin) {
                    setRoomIndex(roomId);
                    setLastSeenChannel(roomId);
                    getHasSeenRooms(teamId, roomId);
                    history.push(`/teams/${roomId}`);
                } else {
                    toast.error('Please use mobile app to access gps channel!');
                }
            } else if (type === 'public') {
                setRoomIndex(roomId);
                setLastSeenChannel(roomId);
                getHasSeenRooms(teamId, roomId);
                history.push(`/teams/${roomId}`);
            } else {
                toast.error('This is new type of Channel:' + type);
            }
        },
        [
            history,
            user,
            //  position, rooms, setUserLocation,
            getHasSeenRooms,
            setLastSeenChannel,
        ]
    );

    const handleCollaspe = useCallback(
        (teamId) => {
            // collapse holds teamId that are showing
            // searching for teamID in collapse
            const index = collapse.indexOf(teamId);
            // if found
            if (index > -1) {
                const tempCollapse = [...collapse];
                // remove the teamID ie: collapse the team
                tempCollapse.splice(index, 1);
                setCollapse(tempCollapse);
            } else {
                // if not found
                // open the team
                setCollapse((prev) => [...prev, teamId]);
                // fetch the rooms
                getRoomsByTeam(teamId);
            }
        },
        [collapse, getRoomsByTeam]
    );

    const getMyTeams = useMemo(() => {
        try {
            const myTeamList = (user.teamList.filter((team) => team.team) || []).sort(sortingMethod);
            const [myTeams, managedTeams, invivtedTeams] = ['owner', 'teamManager', 'user'].map((role) =>
                myTeamList
                    .filter((team) => team.role === role)
                    .map((team) => team.team?._id || team.team?.id || team.team)
            );
            const sortedTeams = {
                my_teams: [],
                teams_managed_by_me: [],
                invited_teams: [],
                other_teams: [],
            };
            (teams || [])?.sort(sortingMethod)?.forEach((team) => {
                const type = [myTeams, managedTeams, invivtedTeams]
                    .map((teamIds, index) => teamIds.includes(team.id || team._id) * (index + 1))
                    .find((x) => x);
                if (type) {
                    sortedTeams[['my_teams', 'teams_managed_by_me', 'invited_teams'][type - 1]].push(team);
                } else {
                    if (canSeePublicTeams(user)) {
                        if (user?.role === 'admin') {
                            sortedTeams.other_teams.push(team);
                        } else {
                            if (team?.noOfPublicRooms > 0) {
                                sortedTeams.other_teams.push(team);
                            }
                        }
                    }
                }
            });
            return sortedTeams;
        } catch (error) {
            console.log(error);
        }
    }, [teams, user]);

    const [teamListObject, setTeamListObject] = useState({});

    useEffect(() => {
        let mainFilteredTeams = getUniqueObjects([...userTeamList, ...teams]);

        const teamList = mainFilteredTeams.reduce((acc, current) => {
            return {
                ...acc,
                [current.id || current._id]: {
                    name: current.name,
                    avatar: current.avatar,
                    id: current.id || current._id,
                },
            };
        }, {});
        setTeamListObject(teamList);
    }, [userTeamList, teams, user.teamList]);

    useEffect(() => {
        const searchTeamChannel = async (search) => {
            let linkedChannelList = linkChannelToTeams(userChannelList, teamListObject);
            if (search === '') {
                setActiveTab('Channels');
                return;
            }
            try {
                let response = [];
                setIsSearching(true);
                if (canSeePublicTeams(user)) {
                    response = await fetchWrapper.get(
                        `chatrooms/search?channelSortBy=title:asc&teamSortBy=name:asc&channelLimit=20&teamLimit=20&searchText=${search}`
                    );
                }
                let myChannelFiltered = linkedChannelList.filter(
                    (room) =>
                        (room?.title?.toLowerCase()?.includes(search?.toLowerCase()) ||
                            room?.subTitle?.toLowerCase()?.includes(search?.toLowerCase())) &&
                        room?.team
                );
                let searchChannelResult = response?.rooms?.filter((room) => room?.teamData);
                let allfilteredChannels = [...myChannelFiltered, ...(searchChannelResult || [])];
                allfilteredChannels = allfilteredChannels.sort((a, b) =>
                    a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
                );
                let uniqueChannels = getUniqueChannels(allfilteredChannels);
                setChannelCount(uniqueChannels?.length || 0);
                setFilteredChannels(uniqueChannels);
                setTotalFilteredChannelPages(Math.ceil((response?.roomCount || 0) / 20));
                setHasMoreFilteredChannels((response?.roomCount || 0) > (response?.rooms?.length || 0));

                let myteamsFiltered = userTeamList.filter((team) =>
                    team.name.toLowerCase().includes(search.toLowerCase())
                );
                let allfilteredTeams = [...myteamsFiltered, ...(response?.teams || [])];
                let uniqueTeams = getUniqueObjects(allfilteredTeams);
                setFilteredTeams((uniqueTeams || []).sort(sortingMethod));
                setTeamCount(uniqueTeams?.length || 0);
                setTotalFilteredTeamPages(Math.ceil((response?.teamCount || 0) / 20));
                setHasMoreFilteredTeams((response?.teamCount || 0) > (response?.teams?.length || 0));
            } catch (err) {
                console.log(err, 'Error');
            } finally {
                setIsSearching(false);
            }
        };
        if (search) {
            searchTeamChannel(search);
            setSearchType(activeTab);
        } else {
            // let publicTeamsIdList = publicTeams?.map((team) => team.id);
            // let publicTeamsArray = teams.filter((team) => publicTeamsIdList.includes(team.id));
            const publicTeams = user?.showPublicChannel ? teams.filter((team) => team.noOfPublicRooms > 0) : [];
            const uniqueTeams = getUniqueObjects([...userTeamList, ...publicTeams]) || [];
            setFilteredTeams(uniqueTeams.sort(sortingMethod));
            setSearchType('Teams');
            setTeamCount(uniqueTeams?.length || 0);
            setActiveTab('Channels');
            setHasMore(true);
        }
    }, [activeTab, search]);

    const clearSearch = () => {
        inputRef.current.value = '';
        setSearch('');
        setActiveTab('Channels');
    };

    const loadFunc = async (page) => {
        try {
            if (!canSeePublicTeams(user)) {
                return;
            }
            if (page <= totalTeamPages && !search) {
                setIsReduxTeamsLoading(true);
                let response = await fetchWrapper.get(
                    `/organizations/5f3569c2647fb5000c019e42/teams?sortBy=name:asc&limit=100&page=${page}`
                );
                setTotalTeamPages(response.totalPages);
                if (response.page >= response.totalPages) {
                    setHasMore(false);
                }
                setTeams(response?.results || [], response?.totalResults);
            } else {
                setHasMore(false);
            }
        } catch (err) {
            console.log(err, 'err');
        } finally {
            setIsReduxTeamsLoading(false);
        }
    };

    const loadFilteredTeamFunc = async (page) => {
        setIsTeamsLoading(true);
        try {
            if (!canSeePublicTeams(user)) {
                return;
            }
            if (page <= totalFilteredTeamPages && !!search) {
                let response = await fetchWrapper.get(
                    `chatrooms/search?teamLimit=20&teamSortBy=name:asc&searchType=team&teamPage=${page}&searchText=${search}`
                );
                setHasMoreFilteredTeams((response?.teamCount || 0) > ((filteredTeams || [])?.length || 0));
                setFilteredTeams((prev) => {
                    const allfilteredTeams = [...(prev || []), ...(response?.teams || response?.results || [])];
                    const uniqueTeams = getUniqueObjects(allfilteredTeams) || [];
                    const sortedUniqueTeams = uniqueTeams.sort(sortingMethod);
                    setTeamCount(uniqueTeams?.length);
                    return [...sortedUniqueTeams];
                });
            } else {
                setHasMoreFilteredTeams(false);
            }
        } catch (err) {
            console.log(err, 'err');
            setHasMoreFilteredTeams(false);
        } finally {
            setIsTeamsLoading(false);
        }
    };

    const loadFilteredChannelFunc = async (page) => {
        setIsChannelsLoading(true);
        try {
            if (!canSeePublicTeams(user)) {
                return;
            }
            if (page <= totalFilteredChannelPages && !!search) {
                let response = await fetchWrapper.get(
                    `chatrooms/search?channelSortBy=title:asc&channelLimit=20&searchType=channel&channelPage=${page}&searchText=${search}`
                );
                setHasMoreFilteredChannels((response?.roomCount || 0) > ((filteredChannels || [])?.length || 0));
                setTotalFilteredChannelPages(Math.ceil((response?.roomCount || 0) / 20));
                setFilteredChannels((prev) => {
                    const allfilteredChannels = [
                        ...(prev || []),
                        ...(response?.rooms?.filter((room) => room?.teamData) || response?.results || []),
                    ];
                    const uniqueChannels = getUniqueObjects(allfilteredChannels) || [];
                    const sortedUniqueChannels = uniqueChannels.sort(sortingMethod);
                    setChannelCount(uniqueChannels?.length);
                    return [...sortedUniqueChannels];
                });
            } else {
                setHasMoreFilteredChannels(false);
            }
        } catch (err) {
            setHasMoreFilteredChannels(false);
            console.log(err, 'err');
        } finally {
            setIsChannelsLoading(false);
        }
    };

    useEffect(() => {
        if (roomId) {
            setRoomIndex(roomId);
        }
    }, [roomId]);

    return (
        <div css={listWrapper} id="style-1">
            <div css={topSection}>
                <div
                    className="d-flex flex-row"
                    css={css`
                        overflow: hidden;
                    `}
                >
                    <span className="w-100">
                        <input
                            placeholder="Search"
                            css={searchInput}
                            ref={inputRef}
                            onChange={debounce(handleSearch, 500)}
                        />
                    </span>

                    {search && (
                        <button
                            css={css`
                                outline: none;
                                color: #191919;
                                padding: 0 1rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 24px;
                                border: none;
                                background: white;
                                svg {
                                    transition: transform 250ms;
                                }
                                :hover > svg {
                                    transform: scale(1.2);
                                }
                            `}
                            onClick={clearSearch}
                        >
                            <AiOutlineClose />
                        </button>
                    )}
                    {user.role === 'user' && getMyTeams && getMyTeams['my_teams'].length === 1 ? null : user.role ===
                          'manager' &&
                      getMyTeams &&
                      getMyTeams['my_teams'].length === 3 ? null : (
                        <Permission role={user.role} perform="create:team" yes={() => <CreateTeam />} />
                    )}
                </div>
            </div>
            {search.length > 0 && (
                <div
                    css={css`
                        display: flex;
                    `}
                >
                    <div
                        css={tabStyle}
                        className={`${searchType === 'Channels' ? 'active' : 'inactive'}`}
                        onClick={() => {
                            setActiveTab('Channels');
                        }}
                    >
                        Channels
                        <span
                            css={css`
                                margin: 0 0.8rem;
                            `}
                        >
                            <Badge variant="light">
                                {isSearching || filteredChannels === null ? (
                                    <BsThreeDots className="threeDotAnime" />
                                ) : (
                                    <Fragment>
                                        {channelCount}
                                        {hasMoreFilteredChannels && '+'}
                                    </Fragment>
                                )}
                            </Badge>
                        </span>
                    </div>
                    <div
                        css={tabStyle}
                        className={`${searchType === 'Teams' ? 'active' : 'inactive'}`}
                        onClick={() => {
                            setActiveTab('Teams');
                        }}
                    >
                        Teams
                        <span
                            css={css`
                                margin: 0 0.8rem;
                            `}
                        >
                            <Badge variant="light">
                                {isSearching || filteredTeams === null ? (
                                    <BsThreeDots className="threeDotAnime" />
                                ) : (
                                    <Fragment>
                                        {teamCount}
                                        {hasMoreFilteredTeams && '+'}
                                    </Fragment>
                                )}
                            </Badge>
                        </span>
                    </div>
                </div>
            )}

            <div css={teamList}>
                <ul
                    className="w-100"
                    css={css`
                        word-break: break-word;
                    `}
                >
                    <div>
                        {isLoading && (
                            <div
                                css={css`
                                    width: 100%;
                                `}
                            >
                                <ChatListSkeleton type="team" />
                            </div>
                        )}
                        {!!search ? null : (
                            <div key={'redux-team-list'} className="height-95" css={StyledScrollBarContainerCSS}>
                                <InfiniteScroll
                                    pageStart={1}
                                    initialLoad={false}
                                    loadMore={loadFunc}
                                    hasMore={!isReduxTeamsLoading && hasMore && !search}
                                    loader={<div></div>}
                                    threshold={50}
                                    useWindow={false}
                                >
                                    <Fragment>
                                        {getMyTeams && Object.values(getMyTeams).flat().length ? (
                                            <Fragment>
                                                {Object.keys(getMyTeams)?.map((sort) => (
                                                    <Fragment key={sort}>
                                                        {getMyTeams[sort].length > 0 && (
                                                            <div className="text-capitalize" css={teamSort}>
                                                                {sort.split('_').join(' ')}
                                                            </div>
                                                        )}
                                                        {getMyTeams[sort].length > 0 &&
                                                            getMyTeams[sort]?.map((team, idx) => {
                                                                return (
                                                                    <Team
                                                                        team={team}
                                                                        key={team?.id || team?._id || idx}
                                                                        user={user}
                                                                        handleCollaspe={handleCollaspe}
                                                                        roomIndex={roomIndex}
                                                                        collapse={collapse}
                                                                        handleRoomClick={handleRoomClick}
                                                                        setOpenDetails={setOpenDetails}
                                                                        setShowChat={setShowChat}
                                                                    />
                                                                );
                                                            })}
                                                    </Fragment>
                                                ))}

                                                {!hasMore && (
                                                    <span
                                                        css={css`
                                                            font-size: 1.8rem;
                                                            width: 100%;
                                                            text-align: center;
                                                            display: inline-block;
                                                            margin: 2rem 0;
                                                        `}
                                                    >
                                                        No more Teams
                                                    </span>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <span
                                                css={css`
                                                    font-size: 1.8rem;
                                                    width: 100%;
                                                    text-align: center;
                                                    display: inline-block;
                                                    margin-top: 2rem;
                                                `}
                                            >
                                                No Team Found
                                            </span>
                                        )}

                                        {!isReduxTeamsLoading ? null : (
                                            <div
                                                className="loader"
                                                key={'readux-team-loader'}
                                                css={css`
                                                    height: 100%;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                `}
                                            >
                                                <VscLoading fontSize={34} className="spin" />
                                            </div>
                                        )}
                                    </Fragment>
                                </InfiniteScroll>
                            </div>
                        )}

                        {!search ? null : searchType === 'Teams' ? (
                            <div key={'filter-team'} className="height-90" css={StyledScrollBarContainerCSS}>
                                <TeamSearchComponent
                                    roomIndex={roomIndex}
                                    teams={filteredTeams}
                                    handleRoomClick={handleRoomClick}
                                    setOpenDetails={setOpenDetails}
                                    setShowChat={setShowChat}
                                    search={search}
                                    hasMore={hasMoreFilteredTeams}
                                    loadFunc={loadFilteredTeamFunc}
                                    isSearching={isSearching}
                                    isTeamsLoading={isTeamsLoading}
                                    collapse={collapse}
                                    handleCollaspe={handleCollaspe}
                                    getMyTeams={getMyTeams}
                                />
                            </div>
                        ) : (
                            <div key={'filter-channel'} className="height-90" css={StyledScrollBarContainerCSS}>
                                <ChannelSearchComponent
                                    roomIndex={roomIndex}
                                    setRoomIndex={setRoomIndex}
                                    channels={filteredChannels}
                                    handleRoomClick={handleRoomClick}
                                    setShowChat={setShowChat}
                                    search={search}
                                    hasMore={hasMoreFilteredChannels}
                                    loadFunc={loadFilteredChannelFunc}
                                    isSearching={isSearching}
                                    isChannelsLoading={isChannelsLoading}
                                />
                            </div>
                        )}
                    </div>
                </ul>
            </div>
            <div className="sidebar-toggle" onClick={() => setMobileSidebar(!mobileSidebar)}>
                {!mobileSidebar && <HiChevronDoubleUp />}
            </div>
        </div>
    );
}

const MapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams,
    totalTeams: state.teams.totalTeams,
    message: state.teams.message,
    isLoading: state.teams.isLoading,
});

export default connect(MapStateToProps, {
    postAvatarTeam,
    editATeam,
    setNewRoom,
    setEditedRoom,
    deleteATeamById,
    setNewTeam,
    removeTeam,
    removeChannel,
    removeUserChannel,
    removeUserTeam,
    getUserData,
    setTeams,
    getRoomsByTeam,
    setUserLocation,
    getHasSeenRooms,
    setLastSeenChannel,
    setConferenceData,
    clearInvitationForConference,
})(TeamList);
