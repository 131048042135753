import { css } from 'twin.macro';
import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { ToolTip } from '../../../_elements/ToolTip';

//styles
import { StyledLinkBtn } from '../../Admin/Tasks/EventModalStyles';

// icons
import { CustFormLabel } from '../../../_elements/formLabel';
import { HiMinusCircle } from 'react-icons/hi';

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const nthDay = ['1st', '2nd', '3rd', '4th'];

export const getRecurrenceData = (recurrenceString, _startDateObject) => {
    const startDateObject = new Date(_startDateObject);
    if (!recurrenceString) {
        return {};
    }
    const presetValues = {
        hourly: '60-minutes',
        daily: '1-days',
        weekly: '1-weeks',
        monthly: '1-months',
        yearly: '1-years',
    };
    const recurrence = presetValues[recurrenceString.toLowerCase()] || recurrenceString;
    const [num, unit, daysString, week] = recurrence.split('-');
    const offset = startDateObject.getDay() - startDateObject.getUTCDay();
    return {
        num: parseInt(num),
        unit,
        days: daysString ? daysString.split('').map((x) => (7 + parseInt(x) + offset) % 7) : [startDateObject.getDay()],
        week: parseInt(week),
    };
};

export const getRecurrenceString = (recurrenceData, offset) => {
    const presetValues = {
        '1-days': 'Daily',
        '1-weeks': 'Weekly',
        '1-months': 'Monthly',
        '1-years': 'Yearly',
        '60-minutes': 'Hourly',
    };
    const recurringDays =
        recurrenceData.days?.filter((x) => x || x === 0)?.map((d) => (7 + parseInt(d) + offset) % 7) || [];
    const recurrence = `${recurrenceData.num}-${recurrenceData.unit}${recurringDays?.length ? `-${recurringDays.join('')}` : ''}${recurrenceData.week || recurrenceData.week === 0 ? `-${recurrenceData.week}` : ''}`;
    return presetValues[recurrence] || recurrence;
};

export default function CusotmRecurrence({
    onRemoveRecurrence,
    eventStartDate,
    eventRecurringUntil,
    recurrenceData,
    setRecurrenceData,
    onRecurringUntilChange,
    recurringMessage,
    recurringUntilError,
}) {
    const eventStartDateObject = new Date(eventStartDate);
    const [startDate, startDay, startWeek] = [
        eventStartDateObject.getDate(),
        eventStartDateObject.getDay(),
        Math.min(3, Math.floor(eventStartDateObject.getDate() / 7)),
    ];

    return (
        <div
            css={css`
                margin: 6px 0;
                display: flex;
                flex-direction: column;
                gap: 6px;
            `}
        >
            <CustFormLabel>Event Recurrence</CustFormLabel>
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    align-items: center;
                `}
            >
                <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                    <span
                        css={css`
                            width: fit-content;
                            flex-shrink: 0;
                        `}
                    >
                        Repeats every
                    </span>
                    <Form.Control
                        // ref={register}
                        type="number"
                        name="recurrence-number"
                        step={1}
                        min={recurrenceData.unit === 'minutes' ? 15 : 1}
                        css={css`
								font-size: 16px !important;
								width: 36px; !important`}
                        value={recurrenceData.num || ''}
                        onChange={(event) => {
                            setRecurrenceData((prev) => ({ ...prev, num: parseInt(event.target.value) }));
                        }}
                        disabled={!setRecurrenceData}
                    />
                    <Form.Control
                        // ref={register}
                        // disabled={disableDuringOnGoingOrView}
                        onChange={(event) => {
                            setRecurrenceData((prev) => ({
                                ...prev,
                                num: event.target.value === 'minutes' && prev.num ? Math.max(prev.num, 15) : prev.num,
                                unit: event.target.value,
                                days: [startDay],
                                week: startWeek,
                            }));
                        }}
                        defaultValue={recurrenceData.unit}
                        as="select"
                        name="recurrence-unit"
                        // defaultValue={eventMode}
                        style={{ fontSize: '16px', width: 'fit-content' }}
                        disabled={!setRecurrenceData}
                    >
                        <option value="minutes">minutes</option>
                        <option value="days">days</option>
                        <option value="weeks">weeks</option>
                        <option value="months">months</option>
                        <option value="years">years</option>
                    </Form.Control>
                </div>
                {['weeks', 'months'].includes(recurrenceData.unit) && (
                    <div
                        className="d-flex"
                        css={css`
                            gap: 8px;
                            align-items: center;
                        `}
                    >
                        <span>on</span>
                        {recurrenceData.unit === 'weeks' ? (
                            days.map((name, index) => (
                                <Fragment>
                                    <ToolTip
                                        text={name}
                                        cssStyles={'left: -100% !important; min-width: 72px !important'}
                                    >
                                        <WeekDayCheckbox
                                            name={name}
                                            key={index}
                                            checked={recurrenceData.days?.includes(index)}
                                            disabled={startDay === index || !setRecurrenceData}
                                            onChecked={
                                                setRecurrenceData
                                                    ? (value) => {
                                                          setRecurrenceData((prev) => ({
                                                              ...prev,
                                                              days: value
                                                                  ? [...new Set([...prev.days, index].sort())]
                                                                  : prev.days.filter((x) => x !== index),
                                                          }));
                                                      }
                                                    : null
                                            }
                                        />
                                    </ToolTip>
                                </Fragment>
                            ))
                        ) : (
                            <div
                                css={css`
                                    display: flex;
                                    gap: 12px;
                                    align-items: center;
                                `}
                            >
                                <Form.Control
                                    value={`${recurrenceData.days[0]}-${recurrenceData.week}`}
                                    as="select"
                                    name="recurrence-days"
                                    style={{ fontSize: '16px', width: 'fit-content' }}
                                    onChange={(event) => {
                                        setRecurrenceData((prev) =>
                                            event.target.value
                                                ? {
                                                      ...prev,
                                                      days: [parseInt(event.target.value.split('-')[0])],
                                                      week: parseInt(event.target.value.split('-')[1]),
                                                  }
                                                : prev
                                        );
                                    }}
                                    disabled={!setRecurrenceData}
                                >
                                    <option>day {startDate}</option>
                                    <option value={`${startDay}-${startWeek}`}>
                                        {nthDay[startWeek]} {days[startDay]}
                                    </option>
                                </Form.Control>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {onRecurringUntilChange || eventRecurringUntil ? (
                <div>
                    <span>Repeats until</span>
                    <DateTimePicker
                        onChange={onRecurringUntilChange}
                        value={
                            typeof eventRecurringUntil === 'string'
                                ? new Date(eventRecurringUntil)
                                : eventRecurringUntil
                        }
                        format="MM-dd-y hh:mm a"
                        disableClock={true}
                        calendarType={'US'}
                        disabled={!onRecurringUntilChange}
                    />
                    <Form.Text className="small text-danger">{recurringUntilError}</Form.Text>
                </div>
            ) : null}

            <Form.Text className="font-italic small">{recurringMessage}</Form.Text>

            {onRemoveRecurrence ? (
                <StyledLinkBtn className="danger" onClick={onRemoveRecurrence}>
                    <HiMinusCircle />
                    Remove recurrence.
                </StyledLinkBtn>
            ) : null}
        </div>
    );
}

function WeekDayCheckbox({ name, onChecked, checked, disabled }) {
    return (
        <span
            className={checked ? 'bg-primary text-white' : 'text-black'}
            css={css`
                width: 28px;
                height: 28px;
                border-radius: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background: #eee;
                font-size: 14px;
            `}
            onClick={() => {
                if (disabled) {
                    return null;
                }
                const newValue = !checked;
                onChecked(newValue);
            }}
        >
            {name[0]}
            {/* <Form.Control type='checkbox' checked={checked} hidden name={`recurrence-day-${index}`}></Form.Control> */}
        </span>
    );
}
