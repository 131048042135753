import React, { Fragment } from 'react';

import CheckboxChecked from './PdfSvg/CheckboxChecked';
import CheckboxUnchecked from './PdfSvg/CheckboxUnchecked';
import RadioButonChecked from './PdfSvg/RadioButonChecked';
import RadioButonUnchecked from './PdfSvg/RadioButonUnchecked';
import {
    getTimeFromDateTime,
    getDateTimeFromDateTime,
    getDateFromDateTime,
    getInitials,
    getRandomColor,
    SafeJSONParse,
} from '../_helpers';
import { formatDate } from '@fullcalendar/core/index.js';
import draftToHtml from 'draftjs-to-html';
import { pdf, Document, Page, View, Image, Text, Link, StyleSheet, Font } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import dayjs from 'dayjs';

export const FormPdf = (userForm, mode = 'blob') => {
    const getFormPdfElement = (key, data = {}) => {
        const urlRegex =
            /(?:(?:https?|ftp|file|http):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
        const styles = StyleSheet.create({
            view: {
                marginBottom: 5,
                paddingTop: 10,
                paddingRight: 5,
                paddingBottom: 10,
                paddingLeft: 10,
                overflowWrap: 'break-word',
            },
            header_title: {
                fontSize: 18,
            },
            header_content: {
                color: '#6c757d',
                fontSize: 12.8,
                paddingLeft: 5,
            },
            font_size_small: {
                fontSize: 12.5,
            },
            form_label: {
                fontSize: 12.5,
                marginBottom: 5,
            },
            form_sub_label: {
                fontSize: 12.5,
                paddingLeft: 5,
            },
            width_two_hundread: {
                width: 200,
                marginBottom: 5,
            },
            form_input_with_border: {
                fontSize: 12.5,
                paddingTop: 6,
                paddingRight: 12,
                paddingBottom: 6,
                paddingLeft: 12,
                backgroundColor: '#e9ecef',
                opacity: 1,
                borderRadius: 4,
                border: '1px solid #ced4da',
            },
            paragraph_content: {
                color: '#212529',
            },

            textarea_min_height: {
                minHeight: 100,
            },

            photo_container_photodiv: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            divider: {
                width: 100,
                height: 5,
            },
            image: {
                maxHeight: 100,
                objectFit: 'contain',
            },
            select_option_color: {
                color: '#6c757d',
            },
            cursor_pointer: {
                cursor: 'pointer',
            },
            pdf_image_size: {
                maxHeight: 100,
                objectFit: 'contain',
            },
            pdf_signature_size: {
                maxHeight: 100,
                objectFit: 'contain',
            },
            single_signature_preview: {
                marginTop: 20,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: '45%',
                boxSizing: 'border-box',
                borderRadius: 3,
                border: '1px solid #343a40',
                height: 150,
                justifyContent: 'center',
            },
            single_signature_preview_text: {
                marginBottom: 20,
                fontSize: 10,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: 5,
                marginTop: 3,
                color: '#606060',
            },
            signature_image: {
                width: '100%',
                objectFit: 'contain',
            },
            no_file_chosen_text: {
                fontSize: 12.5,
                opacity: 1,
            },
        });
        const getInstruction = (instruction) =>
            instruction ? <Text style={styles.smallText}>{instruction}</Text> : <View />;
        const getRequired = (required) =>
            required ? <Text style={styles.marginColor}>{required && '*'}</Text> : <View />;
        const getFieldName = (fieldName, required) => (
            <Text style={styles.warpIt}>
                {fieldName}
                {getRequired(required)}
            </Text>
        );

        let instruction = '';
        let bodyValue;
        let value;
        if (data.instruction) {
            instruction = data.instruction.replace(urlRegex, function (url) {
                let urlTemp;
                if (url.trim()) {
                    if (url.trim().substring(0, 4) !== 'http') {
                        urlTemp = `https://${url.trim()}`;
                        return <Link src={urlTemp}>{url}</Link>;
                    }
                    return <Link src={url}>{url}</Link>;
                }
            });
        }
        switch (key) {
            case 'Header':
                return (
                    <View style={[styles.view, { paddingLeft: 5 }]}>
                        <Text style={styles.header_title}> {data.fieldName} </Text>
                        <Text style={styles.header_content}> {instruction} </Text>
                    </View>
                );
            case 'FullName':
                try {
                    value = JSON.parse(data.value);
                } catch (error) {
                    value = null;
                }
                return (
                    <View style={styles.view}>
                        <View>
                            <Text style={styles.form_label}> {getFieldName(data.fieldName, data.required)} </Text>
                            <Text>{getInstruction(instruction)}</Text>
                        </View>
                        <View style={styles.photo_container_photodiv}>
                            {data.data.map((field, index) => {
                                return (
                                    <View key={index}>
                                        <Text style={[styles.form_input_with_border, styles.width_two_hundread]}>
                                            {value ? value[field.key] : ''}
                                        </Text>
                                        <Text style={styles.form_sub_label}>{field.name}</Text>
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                );
            case 'Email':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            <Text> {getFieldName(data.fieldName, data.required)} </Text>
                            <Text>{getInstruction(instruction)} </Text>
                        </View>
                        <Text style={styles.form_input_with_border}>{data.value}</Text>
                    </View>
                );
            case 'InputField':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <Text style={[styles.form_input_with_border, styles.textarea_min_height]}>{data.value}</Text>
                    </View>
                );
            case 'Divider':
                return <View style={styles.divider}></View>;
            case 'Paragraph':
                try {
                    bodyValue = JSON.parse(instruction);
                    bodyValue = draftToHtml(bodyValue);
                } catch (err) {
                    bodyValue = instruction;
                }
                let cleanFontFamily = bodyValue.replace(/font-family:.*?\;/gm, '');
                let cleanUnderLine = cleanFontFamily.replace(/ins>/gm, 'u>');
                let cleanBodyValue = cleanUnderLine.replace(/del>/gm, 's>');

                const html = `<html><body>${cleanBodyValue}</body></html>`;

                //TODO: Change bodyvalue html to js
                Font.registerEmojiSource({
                    format: 'png',
                    url: 'https://twemoji.maxcdn.com/2/72x72/',
                });
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>{getFieldName(data.fieldName, data.required)}</View>
                        <View style={{ color: '#212529' }}>
                            <Html>{html}</Html>
                        </View>
                    </View>
                );
            case 'Select':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        {data.data.length &&
                            data.data.map((option) => {
                                if (data.value === option.value) {
                                    return (
                                        <View>
                                            <Text style={[styles.font_size_small, styles.select_option_color]}>
                                                {option.text}
                                            </Text>
                                        </View>
                                    );
                                }
                                return <View />;
                            })}
                    </View>
                );
            case 'AccrualPolicySelect':
                const selectedValue = SafeJSONParse(data?.value)?.textLabel;
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <View>
                            <Text style={[styles.font_size_small, styles.select_option_color]}>{selectedValue}</Text>
                        </View>
                    </View>
                );
            case 'Checkbox':
                try {
                    value = JSON.parse(data.value);
                } catch (error) {
                    value = null;
                }
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        {data.data.length &&
                            data.data.map((option, index) => {
                                return (
                                    <View key={index}>
                                        {value && value.includes(option.value) ? (
                                            <CheckboxChecked />
                                        ) : (
                                            <CheckboxUnchecked />
                                        )}
                                        <Text style={[styles.font_size_small, styles.select_option_color]}>
                                            {option.text}
                                        </Text>
                                    </View>
                                );
                            })}
                    </View>
                );
            case 'Radio':
            case 'ServiceRadio':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        {data.data.length &&
                            data.data.map((option, index) => {
                                return (
                                    <View key={index}>
                                        {option.value === data.value ? <RadioButonChecked /> : <RadioButonUnchecked />}
                                        <Text style={[styles.font_size_small, styles.select_option_color]}>
                                            {option.text || option.label}
                                        </Text>
                                    </View>
                                );
                            })}
                    </View>
                );
            case 'TimePicker':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        {/* <Text style={styles.form_input_with_border}>{data.value}</Text> */}

                        <Text style={styles.form_input_with_border}>{getTimeFromDateTime(data.value)}</Text>
                    </View>
                );

            case 'DateTimePicker':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <Text style={styles.form_input_with_border}>{getDateTimeFromDateTime(data.value)}</Text>
                    </View>
                );
            case 'DatePicker':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <Text style={styles.form_input_with_border}>{getDateFromDateTime(data.value)}</Text>
                    </View>
                );
            case 'MultipleSignature':
                return (
                    <View>
                        <View style={styles.single_signature_preview}>
                            {data?.signature ? (
                                <Image
                                    style={styles.signature_image}
                                    src={data.signature + '?noCache=' + Math.random().toString()}
                                />
                            ) : (
                                <View />
                            )}
                        </View>
                        <View style={styles.single_signature_preview_text}>
                            <Text>{data?.name}</Text>
                        </View>
                    </View>
                );
            case 'Signature':
            case 'Image':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <View>
                            {data.value ? (
                                <Image
                                    style={styles.image}
                                    src={data?.value + '?noCache=' + Math.random().toString()}
                                />
                            ) : (
                                <Text style={styles.no_file_chosen_text}>No File Chosen</Text>
                            )}
                        </View>
                    </View>
                );
            case 'AudioFile':
            case 'File':
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <View style={styles.view}>
                            {data.value ? (
                                <Link style={[styles.select_option_color, styles.cursor_pointer]} href={data.value}>
                                    <Text>Click to open</Text>
                                </Link>
                            ) : (
                                <Text style={styles.no_file_chosen_text}>No File Chosen</Text>
                            )}
                        </View>
                    </View>
                );
            case 'Link':
                try {
                    if (data.data) {
                        if (((Array.isArray(data.data) && data.data[0]) || {}).url) {
                            value = data.data[0].url;
                        }
                    }
                } catch (err) {
                    return <View />;
                }
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <View>
                            {value ? (
                                <Link style={[styles.select_option_color, styles.cursor_pointer]} href={data.value}>
                                    {data.value}
                                </Link>
                            ) : (
                                <View />
                            )}
                        </View>
                    </View>
                );
            case 'Photo':
                try {
                    if (data.data) {
                        if ((data.data || {}).file) {
                            value = data.data.file;
                        } else if ((data.data[0] || {}).file) {
                            value = data.data[0].file;
                        }
                    }
                } catch (err) {
                    return <View />;
                }
                return (
                    <View style={styles.view}>
                        <View style={styles.form_label}>
                            {getFieldName(data.fieldName, data.required)}
                            {getInstruction(instruction)}
                        </View>
                        <View>{value ? <Image src={value + '?noCache=' + Math.random().toString()} /> : null}</View>
                    </View>
                );
            case 'DateTimeRangePicker':
                const dates = JSON.parse(data.value) || [];
                return (
                    <View style={styles.view}>
                        <Text style={styles.form_label}>
                            Date{'            '}StartTime{'     '}EndTime
                        </Text>
                        {dates.map((range, index) => (
                            <View key={index}>
                                <Text style={[styles.font_size_small, styles.select_option_color]}>
                                    {formatDate(range.startDateTime)}
                                    {'     '}
                                    {dayjs(range.startDateTime).format('h:mm A')}
                                    {'   '}-{'   '}
                                    {dayjs(range.endDateTime).format('h:mm A')}
                                </Text>
                            </View>
                        ))}
                    </View>
                );
            default:
                return <View />;
        }
    };

    const getPdfComponent = (userForm) => {
        const formData = userForm.formData?.data || [];
        const teamData = userForm?.teamData || {};
        const submission = userForm.data || [];
        const signatureData = (userForm?.signatures || [])?.map((signature) => {
            return {
                key: 'MultipleSignature',
                ...signature,
            };
        });

        const data = formData.map((form) => {
            return {
                ...form,
                value: (submission.find((f) => form._id.toString() === f.fieldName.split('-')[1]) || {}).value,
            };
        });
        const styles = StyleSheet.create({
            header: {
                alignSelf: 'center',
            },
            body: {
                paddingTop: 35,
                paddingBottom: 65,
                paddingHorizontal: 35,
            },
            optonome_header: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                marginBottom: 10,
                borderBottom: 1,
                borderBottomColor: '#6c757d',
                paddingBottom: 10,
            },
            team_header: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                marginBottom: 10,
                borderBottom: 1,
                borderBottomColor: '#6c757d',
                paddingBottom: 10,
            },
            optonome_title: {
                fontSize: 16,
                color: '#50aeb0',
                fontWeight: 'bold',
                marginRight: 50,
            },
            optonome_logo_container: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            team_logo_container: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            },
            no_team_logo_container: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            },
            optonome_logo: {
                height: 50,
                marginRight: 5,
            },
            no_logo: {
                color: '#ffffff',
                borderRadius: 25,
                fontSize: 20,
                width: 50,
                height: 50,
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 5,
            },
            formName: {
                fontSize: 16,
                marginBottom: 10,
            },
            formSubmissionDetail: {
                fontSize: 14,
                marginBottom: 10,
            },
            multiple_signature_container_title: {
                fontSize: 14,
                marginTop: 10,
            },
            multiple_signature_container: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        });
        return (
            <Page style={styles.body} size="A4">
                {!teamData?.name ? (
                    <View style={styles.optonome_header} fixed>
                        <View style={styles.optonome_logo_container}>
                            <Image style={styles.optonome_logo} src="/images/logo-sm.png" />
                        </View>
                        <View>
                            <Text style={styles.optonome_title}>OPTONOME - Communication Made Easy Everywhere</Text>
                        </View>
                    </View>
                ) : (
                    <View style={styles.team_header} fixed>
                        {teamData?.avatar ? (
                            <View style={styles.team_logo_container}>
                                <Image
                                    style={styles.optonome_logo}
                                    src={teamData?.avatar + '?noCache=' + Math.random().toString()}
                                />
                            </View>
                        ) : (
                            <View style={styles.no_team_logo_container}>
                                <View
                                    style={[
                                        { backgroundColor: getRandomColor(teamData?.id || teamData?._id) },
                                        styles.no_logo,
                                    ]}
                                >
                                    <Text>{getInitials(teamData?.name)}</Text>
                                </View>
                            </View>
                        )}
                        <View>
                            <Text style={styles.optonome_title}>{teamData?.name}</Text>
                        </View>
                    </View>
                )}
                <View>
                    <Text style={styles.formName}>Form Name:{userForm.formData?.name}</Text>
                    <Text style={styles.formSubmissionDetail}>Channel: {userForm.chatroomData?.title}</Text>
                    <Text style={styles.formSubmissionDetail}>
                        Submission Details: {getDateTimeFromDateTime(userForm.createdAt)} - {userForm.userData?.name}{' '}
                    </Text>
                </View>
                {data.map((form, index) => (
                    <Fragment key={index}>{getFormPdfElement(form.key, form)}</Fragment>
                ))}
                {!signatureData.length ? null : (
                    <View>
                        <Text style={styles.multiple_signature_container_title}>Signatures:</Text>
                        <View style={styles.multiple_signature_container}>
                            {signatureData.map((form, index) => (
                                <Fragment key={index}>{getFormPdfElement(form?.key, form)}</Fragment>
                            ))}
                        </View>
                    </View>
                )}
            </Page>
        );
    };

    const generateFormPdfBlob = async (userForm) =>
        new Promise(async (resolve, reject) => {
            const blob = await pdf(<Document>{getPdfComponent(userForm)}</Document>).toBlob();
            if (blob) {
                resolve(blob);
            }
        })
            .then((res) => res)
            .catch((err) => console.log(err));

    const combinedFormPdfsBlob = async (userForms) => {
        try {
            const blob = await pdf(
                <Document>
                    {userForms.map((userForm, index) => (
                        <Fragment key={index}>{getPdfComponent(userForm)}</Fragment>
                    ))}
                </Document>
            ).toBlob();
            return blob;
        } catch (error) {
            console.log(error);
        }
    };

    switch (mode) {
        case 'blob':
            return generateFormPdfBlob(userForm);
        case 'combined':
            return combinedFormPdfsBlob(userForm);
        default:
            return <Document>{getPdfComponent(userForm)}</Document>;
    }
};
