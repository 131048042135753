import React, { Fragment } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { TagstyledCounter } from '../Common/styles/UserPanelCommon';
import { css } from 'twin.macro';
import VisitTemplateAccordionModal from './TeamSettings/VisitTemplate/VisitTemplateAccordionModal';

const ServiceCounter = ({ counter, offsetTimestamp, channelId, showTemplate }) => {
    const { seconds, minutes, hours } = useStopwatch({ autoStart: true, offsetTimestamp });

    return (
        <div className="d-flex flex-column justify-content-center align-items-center mr-4">
            {hours < 24 && (
                <TagstyledCounter light small className="tags mb-2">
                    <div css={counterInfo}>
                        <span className="mr-2 ml-2">{counter?.code}</span>
                        <span
                            css={css`
                                height: 9px;
                                width: 9px;
                                border-radius: 4.5px;
                                margin-top: 1px;
                                background-color: #ffffff;
                            `}
                        ></span>
                        <span className="mr-2 ml-2">{counter?.username}</span>
                    </div>
                    <div
                        css={css`
                            width: 200px;
                        `}
                    >
                        <div className="d-flex justify-content-around  align-items-start">
                            <span css={counterTime}>
                                <span css={fontSizeThirty}> {hours < 10 ? '0' + hours : hours} </span>
                                <span css={fontSizTweleve}> Hour</span>
                            </span>
                            <span css={fontSizeThirty}> : </span>
                            <span css={counterTime}>
                                <span css={fontSizeThirty}> {minutes < 10 ? '0' + minutes : minutes} </span>
                                <span css={fontSizTweleve}> Minutes</span>
                            </span>
                            <span css={fontSizeThirty}> : </span>
                            <span css={counterTime}>
                                <span css={fontSizeThirty}> {seconds < 10 ? '0' + seconds : seconds} </span>
                                <span css={fontSizTweleve}> Seconds</span>
                            </span>
                        </div>
                    </div>
                </TagstyledCounter>
            )}
            <div css={counterInfo} style={{ fontSize: '13px' }}>
                <span className="mr-2 ml-2">Units Consumed</span>
                <span>:</span>
                <span className="pr-2">
                    {parseInt((hours * 60 + minutes) / counter?.minutesPerUnit)} / {counter?.availableUnits}
                </span>
                {counter?.template && channelId && showTemplate ? (
                    <VisitTemplateAccordionModal
                        mode={'submit-forms'}
                        data={{ _id: counter?.template, chatroom: channelId }}
                        employeeUserName={counter?.username}
                    />
                ) : null}
            </div>
        </div>
    );
};
const counterInfo = css`
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-evenly;
    background-color: #50aeb0;
    color: #ffffff;
    border-radius: 12px;
    padding: 4px;
    font-weight: 700;
`;

const fontSizTweleve = css`
    font-size: 12px;
`;

const fontSizeThirty = css`
    font-size: 30px;
    font-weight: bold;
`;

const counterTime = css`
    display: flex;
    flex-direction: Column;
    align-items: start;
`;

export default ServiceCounter;
