import { useState, Fragment } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { createChannelValidation } from '../../../_helpers';
import { toast } from 'react-toastify';

import loaderStyles from '../../../_elements/loader';
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';
import { fetchWrapper } from '../../../_helpers';
import LocationComponent from './LocationComponent';
import { VscLoading } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';

const CreateChannelModal = ({ show, setShow, teamId }) => {
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null);
    // const [address, setAddress] = useState(null);
    const history = useHistory();

    const handleCloseClicked = () => {
        // setAddress(false);
        setShow(false);
        setLocation(null);
        setLoading(false);
    };

    const { register, watch, setError, handleSubmit, setValue, getValues, errors } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(createChannelValidation),
    });
    const watchType = watch('type', 'public');

    const onSubmit = async (data) => {
        setLoading(true);
        let tempData = data;
        try {
            if (!data.title || !data.subTitle) {
                return setError('title', {
                    type: 'manual',
                    message: 'Title and Description are required',
                });
            }

            if (data.type === 'gps_enabled' && (data.addresses.length === 0 || data.addresses[0].latitude === '')) {
                return setError('location', {
                    type: 'manual',
                    message: 'Must Verify a location',
                });
            }

            if (
                data.type === 'public' &&
                data.addresses &&
                data.addresses.find((addressData) => addressData.latitude === '')
            ) {
                delete tempData.addresses;
            }

            if (tempData.addresses) {
                tempData.addresses = tempData.addresses.map((addressData) => ({
                    latitude: parseFloat(addressData.latitude),
                    longitude: parseFloat(addressData.longitude),
                }));
            }

            tempData.team = teamId;
            if (data.type === 'gps_enabled' && data.addresses?.length > 0) {
                if (data.addresses[0].latitude === '') {
                    setLoading(false);
                    return setError('location', {
                        type: 'manual',
                        message: 'Must Verify a location',
                    });
                }
            } else if (data.type === 'public' && data.addresses?.length > 0) {
                if (data.addresses[0].latitude === '') {
                    delete tempData.addresses;
                }
            }
            try {
                const res = await fetchWrapper.post(`chatrooms`, tempData);
                // Create channel is handled in TeamList > by socket
                handleCloseClicked();
                toast.success('Channel created successfully');
                history.push(`/teams/${res?._id}`);
            } catch (e) {
                handleCloseClicked();
                toast.error(e.message);
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleCloseClicked}>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-headers">Create A Channel</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        {loading && (
                            <div css={loaderStyles}>
                                <img src="/images/loading.gif" alt="loader" />
                            </div>
                        )}
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label css={formLabel}>
                                Enter Channel Name <span className="red">*</span>
                            </Form.Label>
                            <Form.Control
                                css={formInput}
                                type="text"
                                isInvalid={errors.title}
                                placeholder="Name of Channel"
                                name="title"
                                ref={register}
                            />
                            <Form.Text className="text-danger">{errors.title?.message}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicSubTitle">
                            <Form.Label css={formLabel}>
                                Description <span className="red">*</span>
                            </Form.Label>
                            <Form.Control
                                css={formInput}
                                type="text"
                                isInvalid={errors.subTitle}
                                placeholder="What is your channel about"
                                name="subTitle"
                                ref={register}
                            />
                            <Form.Text className="text-danger">{errors.subTitle?.message}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label css={formLabel}>Choose Channel Type</Form.Label>
                            <Form.Control css={formInput} as="select" name="type" defaultValue="public" ref={register}>
                                <option value="public">Public</option>
                                <option value="private">Private</option>
                                <option value="gps_enabled">GPS Enabled</option>
                            </Form.Control>
                        </Form.Group>
                        {(watchType === 'gps_enabled' || watchType === 'public') && (
                            <LocationComponent
                                formLabel={formLabel}
                                watch={watch}
                                register={register}
                                getValues={getValues}
                                setValue={setValue}
                                errors={errors}
                                location={location}
                                setLocation={setLocation}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between align-items-center">
                        <Button variant="secondary" onClick={handleCloseClicked} disabled={loading}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={loading}>
                            {loading ? <VscLoading className="spin" /> : 'Create Channel'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};
export default CreateChannelModal;
