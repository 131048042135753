import React, { useState } from 'react';
import { css } from 'twin.macro';
import { Button, FormControl, FormLabel } from 'react-bootstrap';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateAProvider } from '../../../../_redux/actions/ActionEVV';
import { connect } from 'react-redux';
import { StyledForm } from './styles';
import ReactSelectComponent from '../../users/ReactSelectComponent';
import TeamSelectFilter from '../../TeamSelectFilter';
import { toast } from 'react-toastify';

//helpers
import { fetchWrapper } from '../../../../_helpers';
import StateSelector from '../StateSelector';
import { stateList } from '../_data/states';
import EvvFormBuilder from '../components/EvvFormBuilder';
import { VscLoading } from 'react-icons/vsc';

const ProviderForm = ({
    formStructure,
    onSubmit,
    provider,
    selectedOption,
    setSelectedOption,
    selectedTeam,
    setSelectedTeam,
    formType,
    selectError,
    teamAlreadyExists,
    setTeamAlreadyExists,
    submitLoading,
    isTeamSettings,
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        getValues,
        setError,
        clearErrors,
    } = useForm({
        // mode: 'onBlur',
        // resolver: yupResolver(providerValidationSchema)
    });

    const [forceExpandAllEvv, setForceExpandAllEvv] = useState(false);

    // immediately set force expand all to false after it is set to true
    useEffect(() => {
        const timeout = setTimeout(() => {
            setForceExpandAllEvv(false);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [forceExpandAllEvv]);

    const [type, setType] = useState({ evv: 'optonome', payroll: 'optonome' });
    const [selectedState, setSelectedState] = useState();

    const [defaultData, setDefaultData] = useState({ evv: provider?.evv || {}, payroll: provider?.payroll || {} });
    const [isMapping, setIsMapping] = useState(false);

    const setTypeFunc = async (formType, value = '') => {
        if (!value) {
            return;
        }
        const presentData = getValues();

        let mappedData = {};
        try {
            setIsMapping(true);
            mappedData = await fetchWrapper.post(
                `evv/map/provider/${defaultData[formType]?.type || 'optonome'}/${value}${selectedTeam ? `?team=${selectedTeam?.value}` : ''}`,
                (presentData[`_${formType}`] || {})[type[formType]]
            );
        } catch (error) {
        } finally {
            setIsMapping(false);
        }

        setDefaultData((prev) => ({ ...prev, [formType]: { type: value, data: mappedData } }));
        return setType((prev) => ({ ...prev, [formType]: value }));
    };

    const typeOptions = {
        evv: {
            sandata: { label: 'Sandata', value: 'sandata' },
            therap: { label: 'Therap', value: 'therap' },
            optonome: { label: 'Optonome', value: 'optonome' },
        },
        payroll: {
            zeal: { label: 'Zeal', value: 'zeal' },
            // worklio: { label: 'Worklio', value: 'worklio' },
            optonome: { label: 'Optonome', value: 'optonome' },
        },
    };

    useEffect(() => {
        if (provider?.agencyName) {
            setValue('agencyName', provider?.agencyName);
        }
        if (provider?.evv) {
            setTypeFunc('evv', provider?.evv?.type || 'optonome');
            setDefaultData((prev) => ({ ...prev, evv: provider?.evv || {} }));
        }
        if (provider?.payroll) {
            setTypeFunc('payroll', provider?.payroll?.type || 'optonome');
            setDefaultData((prev) => ({ ...prev, payroll: provider?.payroll || {} }));
        }
        if (provider?.team) {
            setSelectedTeam({ label: provider.team?.name, value: provider.team?._id });
        }
        if (provider?.state) {
            const state = stateList.find((state) => state.value === provider.state);
            setSelectedState(state);
        }
    }, [formStructure, provider]);

    const handleOnSubmit = (data) => {
        if (!selectedState?.value) {
            return toast.error('Please select the state of the provider.');
        }
        const newData = {
            ...data,
            state: selectedState?.value,
        };
        if (newData._evv) {
            newData._evv = {
                type: type?.evv,
                data: newData._evv?.[type?.evv],
            };
        }
        if (newData._payroll) {
            newData._payroll = {
                type: type?.payroll,
                data: newData._payroll?.[type?.payroll],
            };
        }
        // return
        onSubmit(newData);
    };

    return (
        <StyledForm onSubmit={handleSubmit(handleOnSubmit)}>
            <FormLabel>State</FormLabel>
            <StateSelector
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                isDisabled={formType === 'View'}
            />

            <React.Fragment>
                <FormLabel>Agency</FormLabel>
                <FormControl
                    type="text"
                    name="agencyName"
                    placeholder="Enter Agency's Name"
                    ref={register({
                        pattern: {},
                    })}
                    disabled={formType === 'View'}
                ></FormControl>
                <p className="text-danger">{errors && errors['agencyName']?.message}</p>

                {/* <FormLabel>Vendor</FormLabel> */}
                <FormControl
                    type="text"
                    name="vendor"
                    placeholder="Vendor"
                    ref={register}
                    value="Optonome"
                    css={css`
                        opacity: 0 !important;
                        pointer-events: none;
                        position: absolute;
                    `}
                    readOnly
                ></FormControl>
                {teamAlreadyExists ? (
                    <React.Fragment>
                        <FormLabel>Team</FormLabel>
                        <TeamSelectFilter
                            selectedTeamOption={selectedTeam}
                            setSelectedTeamOption={setSelectedTeam}
                            assignedTo={provider?.team}
                            disabled={isTeamSettings || formType === 'View'}
                            multi={false}
                        />
                        <span className="errorText">{selectError}</span>
                        {!formType && (
                            <div
                                css={css`
                                    margin-top: 0.8rem;
                                    background: #50aeb0;
                                    color: white;
                                    width: max-content;
                                    padding: 0.2em 0.6em;
                                    border-radius: 20px;
                                    cursor: pointer;
                                `}
                                onClick={() => {
                                    setTeamAlreadyExists(false);
                                }}
                            >
                                Assign provider to new Team
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {!formType && !isTeamSettings && (
                            <React.Fragment>
                                <FormLabel>Owner</FormLabel>
                                <ReactSelectComponent
                                    selectedOption={selectedOption}
                                    setSelectedOption={setSelectedOption}
                                    placeholder="eg: John Doe"
                                    assignedTo={selectedOption && selectedOption.value}
                                />
                                <span className="errorText">{selectError}</span>
                                {!formType && (
                                    <div
                                        css={css`
                                            margin-top: 0.8rem;
                                            background: #50aeb0;
                                            color: white;
                                            width: max-content;
                                            padding: 0.2em 0.6em;
                                            border-radius: 20px;
                                            cursor: pointer;
                                        `}
                                        onClick={() => {
                                            setTeamAlreadyExists(true);
                                        }}
                                    >
                                        Click here if the team already exists.
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                <EvvFormBuilder
                    formType="evv"
                    formStructure={formStructure?.evv}
                    isEditMode={!isMapping && !submitLoading && formType === 'Edit'}
                    isViewMode={formType === 'View' || isMapping || submitLoading}
                    type={type?.evv}
                    setTypeFunc={setTypeFunc}
                    typeOptions={typeOptions}
                    allowedTypes={Object.keys(typeOptions?.evv)}
                    typeData={defaultData?.evv || {}}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    forceExpandAll={forceExpandAllEvv}
                />
                <hr style={{ border: '20px solid #ddd', borderRadius: '3px' }} />
                <EvvFormBuilder
                    formType="payroll"
                    formStructure={formStructure?.payroll}
                    isEditMode={!isMapping && !submitLoading && formType === 'Edit'}
                    isViewMode={formType === 'View' || isMapping || submitLoading}
                    type={type?.payroll}
                    setTypeFunc={setTypeFunc}
                    typeOptions={typeOptions}
                    allowedTypes={Object.keys(typeOptions?.payroll)}
                    typeData={defaultData?.payroll || {}}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    forceExpandAll={forceExpandAllEvv}
                />

                <FormControl
                    type="text"
                    name="organization"
                    placeholder="Select Organization"
                    value="5f3569c2647fb5000c019e42"
                    ref={register}
                    css={css`
                        opacity: 0 !important;
                        pointer-events: none;
                    `}
                    readOnly
                />

                {formType === 'View' ? null : (
                    <div className="text-center">
                        <Button
                            className="btn my-2"
                            variant="primary"
                            css={css`
                                width: 100px;
                            `}
                            type="submit"
                            disabled={submitLoading}
                            onClick={() => {
                                setForceExpandAllEvv(true);
                            }}
                        >
                            {submitLoading ? <VscLoading className="spin" /> : formType === 'Edit' ? 'Edit' : 'Create'}
                        </Button>
                    </div>
                )}
            </React.Fragment>
        </StyledForm>
    );
};

const mapStateToProps = (state) => ({
    formStructure: state.evv.evvForms.provider,
});

export default connect(mapStateToProps, { CreateAProvider })(ProviderForm);
