import { css } from 'twin.macro';
import { Fragment, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getStandardDate } from '../../../../../../_helpers';

export const formatDateToLocalISO = (date) => {
    const offset = new Date(date).getTimezoneOffset();
    const localDate = new Date(new Date(date).getTime() - offset * 60 * 1000);
    return localDate.toISOString();
};

export function prepareFormData(formData) {
    try {
        return {
            contractorID: formData.get('contractorID'),
            pay_date: formData.get('pay_date'),
            amount: formData.get('amount'),
            type: formData.get('type'),
            disbursement: {
                method: formData.get('disbursement-method'),
            },
            approval_required: formData.get('approval_required') === 'on',
            speed: formData.get('speed'),
        };
    } catch (error) {
        toast.error('Please check missing fields in form');
    }
}

export default function ZealContractorPaymentForm({
    data,
    mode = 'create',
    onSubmit = () => {},
    isBusy: defaultBusy,
    employee,
}) {
    const [isBusy, setIsBusy] = useState(defaultBusy);
    const [payDate, setPayDate] = useState(() => {
        if (data?.pay_date) {
            return data.pay_date;
        }
        const today = new Date();
        return getStandardDate(today);
    });
    useEffect(() => {
        setIsBusy(defaultBusy);
    }, [defaultBusy]);

    async function handleSubmit(event) {
        event.preventDefault();
        if (mode === 'create' && !employee?.payroll?.metadata?.contractorID) {
            return toast.warn('Please select an Employee(Contractor)');
        }
        const formData = new FormData(event.target);
        onSubmit(prepareFormData(formData));
    }

    return (
        <Fragment>
            <Form
                onSubmit={handleSubmit}
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                `}
                className="contractor-payroll-form"
            >
                {/* Contractor ID */}
                <Form.Control hidden readOnly value={data?.contractorID} name="contractorID" />
                <Form.Control hidden readOnly value={data?.contractorPaymentID} name="contractorPaymentID" />

                <div
                    css={css`
                        display: flex;
                        gap: 12px;
                        padding: 12px;
                        border: 1px solid #eee;
                    `}
                >
                    <Form.Group>
                        <Form.Group
                            css={css`
                                width: 50%;
                            `}
                        >
                            <Form.Label>Pay Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="pay_date"
                                disabled={isBusy}
                                value={payDate}
                                onChange={(e) => setPayDate(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Text>
                            Pay date for this payment. Formatted as a date in ISO-8601 format (YYYY-MM-DD). If the
                            pay_date falls on a weekend/bank holiday or the current time is after 2 PM two days before
                            the given pay_date, please correct the date and resubmit. We will not roll forward the
                            pay_date automatically
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        css={css`
                            min-width: 30%;
                        `}
                    >
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type="number"
                            name="amount"
                            required
                            disabled={isBusy}
                            defaultValue={data?.amount}
                            onWheel={(event) => event.currentTarget.blur()}
                        />
                        <Form.Text>Pay amount of the contractor payment</Form.Text>
                    </Form.Group>
                </div>
                <div
                    css={css`
                        display: flex;
                        gap: 12px;
                        padding: 12px;
                        border: 1px solid #eee;
                    `}
                >
                    <Form.Group
                        css={css`
                            width: 100% !important;
                        `}
                    >
                        <Form.Group>
                            <Form.Label>Disbursement Method</Form.Label>
                            <Form.Control
                                as="select"
                                name="disbursement-method"
                                disabled={isBusy}
                                defaultValue={data?.disbursement?.method}
                            >
                                <option value="direct_deposit">Direct Deposit</option>
                                <option value="paycard">Paycard</option>
                                <option value="prepaid">Prepaid</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Text>Direct Deposit: Make a direct deposit to the employee's bank account</Form.Text>
                        <Form.Text>Paycard: Instantly transfer funds to the existing paycard</Form.Text>
                        <Form.Text>Prepaid: Do not disburse the net pay</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Payment Type</Form.Label>
                        <Form.Control as="select" name="type" disabled={isBusy} defaultValue={data?.type}>
                            <option value="regular">Regular</option>
                            <option value="reimbursement">Reimbursement</option>
                        </Form.Control>
                        <Form.Text>
                            Type of payment. Only regular payments are reported on a contractor's Form 1099-NEC.
                        </Form.Text>
                    </Form.Group>
                </div>
                <div
                    css={css`
                        display: flex;
                        gap: 12px;
                        padding: 12px;
                        border: 1px solid #eee;
                    `}
                >
                    <Form.Group>
                        <Form.Group
                            css={css`
                                display: flex;
                                gap: 6px;
                                align-items: center;
                            `}
                        >
                            <Form.Check
                                name="approval_required"
                                disabled={isBusy}
                                defaultChecked={data?.approval_required}
                            />
                            <Form.Label>Approval Required</Form.Label>
                        </Form.Group>
                        <Form.Text>
                            Set to true if this payment requires manual approval by the employer. Otherwise, by default,
                            Zeal will automatically process the payment two business days before the pay_date
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        css={css`
                            min-width: max-content;
                        `}
                    >
                        <Form.Label>ACH Speed of the Check</Form.Label>
                        <Form.Control as="select" name="speed" disabled={isBusy} defaultValue={data?.speed}>
                            <option value="two_day">Two Day</option>
                            <option value="one_day">One Day</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <hr />

                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        padding: 12px;
                    `}
                >
                    {mode === 'view' ? null : (
                        <Button type="submit" disabled={isBusy}>
                            {mode === 'edit' ? 'Edit' : 'Create'} Contractor Payment
                            {/* We can show final value in modal in confirm window */}
                        </Button>
                    )}
                </div>
            </Form>
        </Fragment>
    );
}
