import React, { Fragment, useEffect, useState } from 'react';
import { fetchWrapper } from '../../../../../../_helpers';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import ReactSelect from 'react-select';
import ContractorPaymentModal from '../PaymentForm/ContractorPaymentModal';
import { VscLoading } from 'react-icons/vsc';
import Swal from 'sweetalert2';
import ContractorPaymentRow from './ContractorPaymentRow';

function ZealContractorPayroll({ employee, teamId }) {
    const contractorID = employee?.data?.basic?.contractorID || employee?.metadata?.contractorID;
    const companyID = employee?.data?.basic?.companyID;

    const [status, setStatus] = useState(null);

    const [contractorPayments, setContractorPayments] = useState([]);
    const [isBusy, setIsBusy] = useState(false);

    const [showContractorPayment, setShowContractorPayment] = useState({});

    async function fetchContractorPayments(companyID, contractorID, status) {
        try {
            setIsBusy(true);
            const response = await fetchWrapper.get(
                `/evv/payroll/zeal/contractor/${companyID}/${contractorID}/payments?${status ? `status=${status}` : ''}${teamId ? `&team=${teamId}` : ''}`
            );
            setContractorPayments(response);
        } catch (error) {
            if (error.name === 'AbortError') return;
            toast.error("Couldn't fetch (Contractor) payments");
        } finally {
            setIsBusy(false);
        }
    }

    async function handleDeletePayment(paymentData) {
        if (isBusy) {
            return;
        }
        try {
            const companyID = paymentData?.companyID;
            if (!companyID) {
                throw new Error('Company ID is missing');
            }
            if (!paymentData?.contractorPaymentID) {
                throw new Error('Employee(Contractor) Contractor Payment ID is missing');
            }
            const response = await Swal.fire({
                title: `You are deleting following payment:
                 <br/> <p style="font-size: 14px;">${paymentData.first_name} ${paymentData.last_name} ${paymentData.contractorPaymentID}</p>
                 `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm Delete',
            });
            if (!response.isConfirmed) {
                return;
            }
            setIsBusy(true);
            await fetchWrapper.delete(
                `/evv/payroll/zeal/contractor/${companyID}/${paymentData.contractorPaymentID}/payments${teamId ? `?team=${teamId}` : ''}`
            );
            toast.success(`Payment deleted successfully!`);
            setContractorPayments((prev) =>
                prev.filter(({ contractorPaymentID }) => contractorPaymentID !== paymentData?.contractorPaymentID)
            );
        } catch (error) {
            toast.error(error.message || `Couldn't delete payment!`);
        } finally {
            setIsBusy(false);
        }
    }

    async function handleApprovePayment(paymentData) {
        if (isBusy) {
            return;
        }
        try {
            const companyID = paymentData?.companyID;
            if (!companyID) {
                throw new Error('Company ID is missing');
            }
            if (!paymentData?.contractorPaymentID) {
                throw new Error('Employee(Contractor) Contractor Payment ID is missing');
            }
            const response = await Swal.fire({
                title: `You are approving following payment:
                 <br/> <p style="font-size: 14px;">${paymentData.first_name} ${paymentData.last_name} ${paymentData.contractorPaymentID}</p>
                 `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm Approve',
            });
            if (!response.isConfirmed) {
                return;
            }
            setIsBusy(true);

            const res = await fetchWrapper.patch(
                `/evv/payroll/zeal/contractor/${companyID}/${paymentData.contractorPaymentID}/payments${teamId ? `?team=${teamId}` : ''}`
            );
            toast.success(`Payment approved successfully!`);
            handleUpdatePayments(res);
        } catch (error) {
            toast.error(error.message || `Couldn't delete payment!`);
        } finally {
            setIsBusy(false);
        }
    }

    const handleUpdatePayments = (updatedPaymentData) => {
        setContractorPayments((prev) =>
            prev.map((payment) =>
                payment?.contractorPaymentID === updatedPaymentData?.contractorPaymentID
                    ? { ...updatedPaymentData }
                    : payment
            )
        );
    };

    useEffect(() => {
        if (showContractorPayment?.mode === 'delete' && showContractorPayment?.data?.contractorPaymentID) {
            handleDeletePayment(showContractorPayment.data);
        }
        if (showContractorPayment?.mode === 'approve' && showContractorPayment?.data?.contractorPaymentID) {
            handleApprovePayment(showContractorPayment.data);
        }
    }, [showContractorPayment]);

    useEffect(() => {
        if (companyID && contractorID) {
            fetchContractorPayments(companyID, contractorID, status);
        }
    }, [status, companyID, contractorID]);

    return (
        <div
            className="w-100 d-flex flex-column align-items-center"
            css={css`
                overflow-x: hidden;
                gap: 12px;
                padding: 12px;
            `}
        >
            <div className="d-flex flex-row w-100 align-items-end justify-content-between">
                <span>Employee(Contractor) Payments</span>
                <div
                    css={css`
                        margin-left: auto;
                        display: flex;
                        flex-direction: row;
                        gap: 6px;
                        align-items: center;
                        justify-content: flex-end;
                    `}
                >
                    <div>Payment Status: </div>
                    <div
                        css={css`
                            width: 256px;
                        `}
                    >
                        <ReactSelect
                            options={[
                                { label: 'Pending', value: 'pending' },
                                { label: 'Pre-Processed', value: 'pre-processed' },
                                { label: 'Processed', value: 'processed' },
                            ]}
                            value={
                                status
                                    ? {
                                          label: {
                                              pending: 'Pending',
                                              'pre-processed': 'Pre-Processed',
                                              processed: 'Processed',
                                          }[status],
                                          value: status,
                                      }
                                    : null
                            }
                            onChange={(option) => {
                                setStatus(option?.value || '');
                            }}
                            isDisabled={isBusy}
                            isClearable
                        />
                    </div>
                </div>
            </div>
            <Fragment>
                {isBusy ? (
                    <VscLoading
                        className="spin"
                        size="24px"
                        css={css`
                            overflow: hidden;
                        `}
                    />
                ) : (
                    <Fragment>
                        {contractorPayments?.length ? (
                            <div
                                css={css`
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 12px;
                                `}
                            >
                                {contractorPayments
                                    .sort((c1, c2) => new Date(c2?.pay_date) - new Date(c1?.pay_date))
                                    .map((contractorPayment, index) => (
                                        <ContractorPaymentRow
                                            key={index}
                                            contractorPayment={contractorPayment}
                                            setShowContractorPayment={(data, mode = 'view') => {
                                                setShowContractorPayment({ data, mode });
                                            }}
                                            companyID={companyID}
                                            teamId={teamId}
                                            fromPayroll={true}
                                        />
                                    ))}
                            </div>
                        ) : (
                            <div>No Payments Found</div>
                        )}
                    </Fragment>
                )}
            </Fragment>
            <ContractorPaymentModal
                show={
                    !!showContractorPayment?.data?.contractorID &&
                    ['view', 'edit'].includes(showContractorPayment?.mode)
                }
                handleClose={() => {
                    setShowContractorPayment({});
                }}
                companyID={companyID}
                contractorPayment={showContractorPayment?.data || {}}
                mode={showContractorPayment?.mode || 'view'}
                teamId={teamId}
                handleUpdatePayments={handleUpdatePayments}
            />
        </div>
    );
}

export default ZealContractorPayroll;
