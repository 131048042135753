import React, { useEffect } from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import ZealForm from './ZealForm';
import { toast } from 'react-toastify';

import { MdAddCard } from 'react-icons/md';
import { ToolTip } from '../../../../_elements/ToolTip';
import { fetchWrapper } from '../../../../_helpers';
import ReactSelect from 'react-select';
import { UpdateAnEmployee } from '../../../../_redux/actions/ActionEVV';
import { useDispatch } from 'react-redux';

const ZealTaskSetup = ({ employee, companyID, employeeID, contractorID, isEmployee, onboarded = false, teamId }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [fetchingForm, setFetchingForm] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [zealFormStructure, setZealFormStructure] = useState({});

    const [states, setStates] = useState(['US']);
    const [allStates, setAllStates] = useState([]);
    const [defaultStates, setDefaultStates] = useState([]);

    const handleModalShow = () => {
        setShowModal(true);
    };

    const fetchZealTaskFormStructure = async () => {
        try {
            setFetchingForm(true);
            const response = await fetchWrapper.get(
                `evv/payroll/zeal/tax-parameters?companyID=${companyID}&employeeID=${employeeID}&jurisdictions=${states}${teamId ? `&team=${teamId}` : ''}`
            );
            setStates(Object.keys(response || {}));
            if (defaultStates.length === 0) {
                setDefaultStates(Object.keys(response || {}));
            }
            setZealFormStructure(response);
        } catch (e) {
            console.log('get zeal structure', e);
        } finally {
            setFetchingForm(false);
        }
    };

    useEffect(() => {
        const fetchedStates = Object.keys(zealFormStructure);
        if (
            showModal &&
            isEmployee &&
            (states.length !== fetchedStates.length || !states.every((state) => fetchedStates.includes(state)))
        ) {
            fetchZealTaskFormStructure();
        }
    }, [states, showModal, zealFormStructure]);

    useEffect(() => {
        if (companyID && showModal) {
            fetchWrapper
                .get(`evv/payroll/zeal/provider/${companyID}/work-locations${teamId ? `?team=${teamId}` : ''}`)
                .then((workLocations) => {
                    setAllStates(Array.from(new Set(workLocations.map(({ state }) => state))));
                });
        }
    }, [companyID, showModal]);

    const CreateZeal = async (newZeal) => {
        try {
            const response = await fetchWrapper.post(
                `evv/payroll/zeal/tax-parameters${teamId ? `?team=${teamId}` : ''}`,
                {
                    companyID,
                    employeeID,
                    taxParameters: newZeal,
                }
            );
            console.log('create zeal response', response);
            toast.success('Form Submitted Successfully');
            return true;
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            console.log(error);
            toast.error(error?.message || 'Form Submission Failed');
            return false;
        }
    };

    const onSubmit = async (data) => {
        try {
            setIsFormSubmitting(true);
            const success = await CreateZeal(data);
            setShowModal(!success);
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            console.log(err);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const handleCompleteOnboarding = async () => {
        try {
            setIsFormSubmitting(true);
            if (isEmployee) {
                await fetchWrapper.post(
                    `evv/payroll/zeal/employee/${companyID}/${employeeID}/onboarding${teamId ? `?team=${teamId}` : ''}`
                );
            } else {
                await fetchWrapper.post(
                    `evv/payroll/zeal/contractor/${companyID}/${contractorID}/onboarding${teamId ? `?team=${teamId}` : ''}`
                );
            }
            dispatch(
                UpdateAnEmployee({
                    ...employee,
                    payroll: { ...employee.payroll, metadata: { ...employee.payroll.metadata, onboarded: true } },
                })
            );
            toast.success('Onboarding successfully completed');
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error?.message);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    return (
        <>
            <ToolTip text={'Zeal Tax Setup'} side={'top'} cssStyles={'top:-70% !important;'}>
                <MdAddCard className="icon edit" onClick={handleModalShow} />
            </ToolTip>
            <Modal
                show={showModal}
                scrollable
                backdrop="static"
                backdropClassName="bg-dark"
                onHide={() => setShowModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <div className="header d-flex flex-row">
                        <div>
                            <div
                                css={css`
                                    margin: 0;
                                    font-size: larger;
                                `}
                            >
                                Zeal Tax Setup
                            </div>
                        </div>
                        <div className="mt-1 ml-4">
                            {onboarded ? (
                                <span className="badge badge-success">Onboarded</span>
                            ) : (
                                <span className="badge badge-danger">Not Onboarded</span>
                            )}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body
                    className="modal-body"
                    css={css`
                        height: 600px;
                    `}
                >
                    <div
                        css={css`
                            height: 100%;
                            position: relative;
                        `}
                    >
                        <div>Jurisdictions</div>
                        <ReactSelect
                            isMulti
                            options={allStates.map((state) => ({
                                label: state,
                                value: state,
                                isFixed: defaultStates.includes(state),
                            }))}
                            value={states.map((state) => ({
                                label: state,
                                value: state,
                                isFixed: defaultStates.includes(state),
                            }))}
                            onChange={(options, actionMeta) => {
                                switch (actionMeta.action) {
                                    case 'remove-value':
                                    case 'pop-value':
                                        if (actionMeta.removedValue.isFixed) {
                                            return;
                                        }
                                    case 'clear':
                                        return setStates(defaultStates);
                                    default:
                                        setStates(options.map(({ value }) => value));
                                }
                            }}
                            isDisabled={isFormSubmitting || fetchingForm}
                            styles={{
                                multiValueRemove: (base, state) =>
                                    state.data.isFixed ? { ...base, display: 'none' } : base,
                                multiValueLabel: (base, state) =>
                                    state.data.isFixed ? { ...base, paddingRight: 6, fontWeight: 'bold' } : base,
                            }}
                        />
                        <hr />
                        <ZealForm
                            onSubmit={onSubmit}
                            formType="Create"
                            isFormSubmitting={isFormSubmitting}
                            formStructure={zealFormStructure}
                            fetchingForm={fetchingForm}
                            handleCompleteOnboarding={handleCompleteOnboarding}
                            isEmployee={isEmployee}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ZealTaskSetup;
