import ID from '../../../_helpers/uuid';

export const payroll = () => {
    return {
        defaultZealEmployeeFormElements: [
            {
                data: [],
                id: ID.uuid(),
                key: 'Header',
                fieldName: 'Basic Information',
                type: 'textbox',
                instruction: '#Note: This form should be submitted by Employee only',
            },
            {
                required: true,
                data: [],
                key: 'Email',
                fieldName: 'Email',
                type: 'textbox',
                autofill: 'email',
                instruction: 'Valid email address is in the format yourname@example.com',
            },
            {
                required: true,
                data: [
                    {
                        key: 'firstName',
                        name: 'First Name',
                        type: 'text',
                        autofill: 'firstName',
                    },
                    {
                        key: 'lastName',
                        name: 'Last Name',
                        type: 'text',
                        autofill: 'lastName',
                    },
                ],
                id: ID.uuid(),
                key: 'FullName',
                fieldName: 'Full Name',
                type: 'textbox',
                instruction: '',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'DatePicker',
                fieldName: 'Date Of Birth',
                type: 'date',
                instruction: '',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Social Security Number',
                type: 'textbox',
                instruction: 'SSN must be a 9 digit number',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Address Line 1',
                type: 'textbox',
                instruction: 'Residential Street Address (e.g. 456 Park Avenue)',
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Address Line 2',
                type: 'textbox',
                instruction: 'Residential Street Address Line 2 (i.e. apt numbers, suite no., etc.)',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Residential City',
                type: 'textbox',
                instruction: '(e.g. New York)',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'State',
                type: 'textbox',
                instruction: 'State should be in standard two letter short form (e.g. NY)',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Zip',
                type: 'textbox',
                instruction: 'Zip code should be a number from 5 to 9 digits',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Phone Number',
                type: 'textbox',
                instruction: 'Phone number should be a number up to 10 digits',
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'Header',
                fieldName: 'Bank Account',
                type: 'textbox',
                instruction: '',
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Institution Name',
                type: 'textbox',
                instruction: '',
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Account Number',
                type: 'textbox',
                instruction: '',
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Routing Number',
                type: 'textbox',
                instruction: '',
            },
            {
                required: false,
                data: [
                    {
                        text: 'Checking',
                        value: 'checking',
                    },
                    {
                        text: 'Savings',
                        value: 'savings',
                    },
                ],
                id: ID.uuid(),
                key: 'Select',
                fieldName: 'Account Type',
                type: 'select',
                instruction: '',
            },
        ],
        defaultPtoFormElements: [
            {
                data: [],
                fieldName: 'Accrual Policy',
                id: ID.uuid(),
                key: 'AccrualPolicySelect',
                type: 'select',
                required: true,
            },
            {
                fieldName: 'Datetime Range',
                id: ID.uuid(),
                key: 'DateTimeRangePicker',
                type: 'datetimerange',
                required: true,
            },
            {
                fieldName: 'Signature',
                id: ID.uuid(),
                key: 'Signature',
                type: 'signature',
                required: true,
            },
        ],
    };
};
