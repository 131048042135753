import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { HashRouter, useHistory } from 'react-router-dom';

//icons
import { TeamSettingsStyled } from './styles/TeamSettingsStyled';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import SideBar from '../../Layouts/SideBar/SideBar';
import Dash from './Dash';
import { getTeamIdFromURL } from '../../../../_helpers';
import { fetchWrapper } from '../../../../_helpers';
import { setNewTeam } from '../../../../_redux';
import { toast } from 'react-toastify';

function TeamSettings({ currUser, setNewTeam }) {
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const history = useHistory();
    const teamId = getTeamIdFromURL();
    useEffect(() => {
        if (teamId) {
            fetchWrapper
                .get(`/organizations/5f3569c2647fb5000c019e42/teams/${teamId}`)
                .then(async (data) => {
                    setNewTeam(data);
                })
                .catch((error) => {
                    if (error?.name === 'AbortError') return;
                    console.log('new err', error);
                    toast.error(error?.message);
                    if (error?.code === 404) {
                        history.push('/not-found');
                    }
                });
        }
    }, []);

    const goToTeamPage = () => {
        history.push(`${currUser?.lastSeenChannel ? `/teams/${currUser?.lastSeenChannel}#0` : '/teams'}`);
    };
    return (
        <div css={TeamSettingsStyled}>
            <SwitchTransition>
                <CSSTransition key={mobileSidebar} timeout={500} classNames={'sidebar-ani-'}>
                    <SideBar mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
                </CSSTransition>
            </SwitchTransition>
            <HashRouter>
                <Dash goToTeamPage={goToTeamPage} />
            </HashRouter>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currUser: state.user.user,
    teams: state.teams.teams,
});

export default connect(mapStateToProps, { setNewTeam })(TeamSettings);
