import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionSubmissionModal from './AccordionSubmissionModal';
import { FaWpforms } from 'react-icons/fa';
import { css } from 'twin.macro';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import FormsTableViewEvent from './FormsTableViewEvent';
import OurAccordionMultiple from '../../../_elements/OurAccordionMultiple';

const FormTitleDiv = ({ form, submitted }) => {
    return (
        <div
            className="pointer d-flex"
            css={css`
                align-items: center;
                gap: 6px;
                font-size: 16px;
            `}
        >
            <FaWpforms />
            <span>{form.name}</span>
            <span className="badge badge-info">
                {
                    {
                        eos: 'end of service',
                        sos: 'start of service',
                        multipleSubmission: 'multiple submission',
                        normal: 'normal',
                        speciality: {
                            timeOff: 'time off',
                            zealEmployee: 'zeal onboarding',
                            evvVisitBackupForm: 'evv visit backup form',
                        }[form.specialityForm?.type],
                    }[form.type]
                }
            </span>
            {'|'}
            <span>
                Submission Status:
                {submitted ? (
                    <HiOutlineCheckCircle size={30} className="text-primary cursor-pointer ml-2" />
                ) : (
                    <HiOutlineExclamationCircle size={30} className="text-secondary cursor-pointer ml-2" />
                )}
            </span>
        </div>
    );
};

const FormViewAccordion = ({
    selectedForm = [],
    selectedUsers = [],
    userId,
    currUser,
    currRoom,
    team,
    position,
    checkIsShowing,
    subTasks = [],
    data,
    canSeeAllStatus,
}) => {
    const [activeKey, setActiveKey] = useState('0');
    const userSubTask = subTasks?.find((subTask) => subTask?.assignedUser === currUser?._id) || {};

    const filteredForms =
        userSubTask?.forms
            ?.map((subTaskForm) => ({
                ...subTaskForm,
                form: selectedForm.find((form) => form._id === subTaskForm.form),
            }))
            ?.filter((subTaskForm) => subTaskForm?.form?._id)
            ?.sort((a, b) => a.submitted - b.submitted) ||
        (selectedForm || []).map((form) => ({ form, isDisabled: true })) ||
        [];
    return filteredForms?.length ? (
        <div
            css={css`
                border: 2px solid #50aeb0;
            `}
        >
            <Accordion defaultActiveKey={activeKey} onSelect={(e) => setActiveKey(e)}>
                {filteredForms?.map(({ form, submitted, isDisabled }, formIndex) => (
                    <OurAccordionMultiple
                        title={<FormTitleDiv form={form} submitted={submitted} />}
                        eventKey={formIndex.toString()}
                        key={form?._id || form?.id}
                        isOpen={activeKey === formIndex.toString()}
                    >
                        {activeKey === formIndex.toString() && (
                            <div
                                css={css`
                                    padding: 2px;
                                `}
                            >
                                <AccordionSubmissionModal
                                    form={form}
                                    roomId={currRoom && (currRoom?.id || currRoom?._id)}
                                    userId={userId}
                                    currRoom={currRoom}
                                    formType={form?.type}
                                    team={team}
                                    position={position}
                                    checkIsShowing={checkIsShowing}
                                    activeKey={activeKey}
                                    isAccordionForm={true}
                                    taskId={data?._id || data?.id}
                                    subTasks={data?.subTasks || []}
                                    isDisabled={isDisabled}
                                />
                                <hr />
                                {!canSeeAllStatus ? null : (
                                    <FormsTableViewEvent
                                        selectedForm={form}
                                        selectedUsers={selectedUsers?.filter(
                                            (user) => user._id === currUser._id || canSeeAllStatus
                                        )}
                                        subTasks={data?.subTasks || []}
                                        canSubmitForm={true}
                                        userId={userId}
                                        currRoom={currRoom}
                                        team={team}
                                        position={position}
                                        checkIsShowing={checkIsShowing}
                                    />
                                )}
                            </div>
                        )}
                    </OurAccordionMultiple>
                ))}
            </Accordion>
        </div>
    ) : null;
};

export default FormViewAccordion;
