import { css } from 'twin.macro';
import React, { useState, Fragment } from 'react';
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { fetchWrapper } from '../../../_helpers';
import StaticPreview from '../../Admin/Form/StaticPreview';
import { FormPdf } from '../../../_elements/FormPdf';
import { PDFViewer } from '@react-pdf/renderer';
import { BsEye } from 'react-icons/bs';

export default function ManagerFormView({
    roomId,
    form,
    hideSubmissionsCount,
    handleFormClosed = () => {},
    handleFormOpened = () => {},
}) {
    const [show, setShow] = useState(false);
    const [userData, setUserData] = useState({});
    const [userList, setUserList] = useState([]);
    const [userForms, setUserForms] = useState([]);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const handleUserChange = (e) => {
        const userForm = userForms.find((form) => form._id === e.target.value);
        setTimeout(() => {
            const tempForm = { ...form };
            if (userForm)
                setUserData({
                    dataArray:
                        tempForm.data.map((form) => ({
                            ...form,
                            value: userForm.data.find((f) => form._id === f.fieldName.split('-')[1])?.value,
                        })) || [],
                    formId: e.target.value,
                });
        }, 0);
    };

    const handleClose = () => {
        setShow(false);
        handleFormClosed();
    };

    const [key, setKey] = useState('staticform');

    const handleShow = () => {
        const getUserForms = async () => {
            try {
                setIsLoadingForm(true);
                let response = await fetchWrapper.get(`/userFormData?limit=3000&&chatroom=${roomId}&&form=${form._id}`);
                let results = response.results;
                setUserForms(results);
                const tempForm = { ...form };
                if (results.length) {
                    setUserData({
                        dataArray:
                            tempForm.data.map((form, index) => ({
                                ...form,
                                value: results[0].data.find((f) => form._id === f.fieldName.split('-')[1])?.value,
                            })) || [],
                        formId: results[0]?._id,
                    });
                    setUserList([
                        ...results.map((form) => {
                            if (form.userData !== undefined) {
                                return {
                                    ...form.userData,
                                    formId: form._id,
                                    createdDate: form.createdAt,
                                };
                            }
                            return null;
                        }),
                    ]);
                }
                setIsLoadingForm(false);
            } catch (err) {
                setIsLoadingForm(false);
                console.log(err);
            }
        };
        getUserForms();
        setShow(true);
        handleFormOpened();
    };
    return (
        <Fragment>
            <p onClick={handleShow} className="pointer ml-2">
                {/* <BsEye className="icon view mr-2" /> */}
                <span className="small text-muted">
                    {hideSubmissionsCount ? 'View My ' : ''}Submissions
                    {hideSubmissionsCount ? null : `: ${form.noOfSubmission}`}
                </span>
            </p>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header>Submitted Forms</Modal.Header>
                <Modal.Body>
                    {isLoadingForm ? (
                        'loading ...'
                    ) : (
                        <Fragment>
                            {(userData?.dataArray || []).length ? (
                                <Fragment>
                                    <Form.Group>
                                        <Form.Label>Select user</Form.Label>
                                        <Form.Control as="select" onChange={handleUserChange}>
                                            {userList.length &&
                                                userList.map((user, idx) =>
                                                    user ? (
                                                        <option key={user.id + idx} value={user.formId}>
                                                            {user.name} ({new Date(user.createdDate).toLocaleString()})
                                                        </option>
                                                    ) : (
                                                        <option>Deleted User</option>
                                                    )
                                                )}
                                        </Form.Control>
                                    </Form.Group>
                                    {userData?.formId ? (
                                        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                                            <Tab eventKey="staticform" title="Static form">
                                                {key === 'staticform' && (
                                                    <StaticPreview
                                                        key={userData?.formId}
                                                        formElements={userData.dataArray}
                                                        onlyView={true}
                                                    />
                                                )}
                                            </Tab>
                                            <Tab
                                                eventKey="pdf"
                                                title="PDF"
                                                className="tabtab"
                                                css={css`
                                                    color: $495057;
                                                `}
                                            >
                                                {key === 'pdf' && (
                                                    <div
                                                        css={css`
                                                            height: 70vh;
                                                            overflow: auto;
                                                        `}
                                                    >
                                                        <PDFViewer
                                                            width="100%"
                                                            height="100%"
                                                            css={css`
                                                                border: none;
                                                                outline: none;
                                                            `}
                                                            showToolbar={false}
                                                        >
                                                            {FormPdf(
                                                                userForms.find((form) => form._id === userData?.formId),
                                                                'component'
                                                            )}
                                                        </PDFViewer>
                                                    </div>
                                                )}
                                            </Tab>
                                        </Tabs>
                                    ) : null}
                                </Fragment>
                            ) : (
                                <div
                                    css={css`
                                        min-height: 20rem;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: gray;
                                    `}
                                >
                                    No submission yet!
                                </div>
                            )}
                        </Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
