import React from 'react';
import { css } from 'twin.macro';
import { ActionStyles } from '../../../../reusableStyles';

import { FaRegCalendarAlt } from 'react-icons/fa';
import { BsEye, BsPencil, BsTrash } from 'react-icons/bs';
import { connect } from 'react-redux';

import { ToolTip } from '../../../../../../_elements/ToolTip';
import { Button } from 'react-bootstrap';
import { PayHighlight } from '../ViewPayments/ZealCompanyPayroll';
import { getUserRole } from '../../../../../../_helpers';

function ContractorPaymentRow({ contractorPayment, setShowContractorPayment, teamId, preview = false, currUser }) {
    const canEdit = ['admin', 'owner', 'teamManager'].includes(getUserRole(currUser, teamId));

    return (
        <div
            css={css`
                border: 2px solid #ddd;
                border-radius: 3px;
                padding: 6px 12px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    justify-content: space-between;
                `}
            >
                <div>
                    <div
                        css={css`
                            font-size: larger;
                        `}
                    >
                        {[
                            contractorPayment?.first_name,
                            contractorPayment?.middle_initial,
                            contractorPayment?.last_name,
                        ]
                            .filter((x) => x)
                            .join(' ')}
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            align-items: start;
                        `}
                    >
                        <span
                            css={css`
                                font-size: small;
                            `}
                        >
                            {contractorPayment?.contractorPaymentID}
                        </span>
                        <div
                            className="d-flex align-items-center"
                            css={css`
                                gap: 6px;
                            `}
                        >
                            <FaRegCalendarAlt />
                            <span>{contractorPayment.pay_date}</span>
                            <span
                                className={`badge badge-sm ${contractorPayment?.status === 'pending' ? 'badge-warning' : contractorPayment?.status === 'processed' ? 'badge-success' : contractorPayment?.status === 'failed' ? 'badge-danger' : 'badge-info'}`}
                            >
                                {contractorPayment?.status}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        gap: 24px;
                        flex-grow: 1;
                        margin: 0 24px;
                    `}
                >
                    {['amount'].map((key) => (
                        <React.Fragment key={key}>
                            {(contractorPayment || {})[key]?.toString() && (
                                <PayHighlight amount={contractorPayment[key]} label={key.replace('total_', '')} />
                            )}
                        </React.Fragment>
                    ))}
                    <PayHighlight
                        amount={(contractorPayment?.type || '')?.toUpperCase()}
                        label={'Payment Type'}
                        dollars={false}
                    />
                    <PayHighlight
                        amount={(contractorPayment?.disbursement?.method || '')?.toUpperCase()}
                        label={'Disbursement Method'}
                        dollars={false}
                    />
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        align-items: flex-end;
                        justify-content: flex-end;
                    `}
                >
                    {contractorPayment?.approval_required && !contractorPayment?.approved && canEdit ? (
                        <Button onClick={() => setShowContractorPayment(contractorPayment, 'approve')}>
                            Approve Payment
                        </Button>
                    ) : null}
                    <div className="d-flex flex-row align-items-end">
                        {preview || !canEdit || (
                            <ToolTip
                                text={'View Payment'}
                                side="right"
                                cssStyles={css`
                                    right: 42px !important;
                                    top: 0 !important;
                                `}
                            >
                                <ActionStyles>
                                    <BsEye
                                        className="icon"
                                        onClick={() => setShowContractorPayment(contractorPayment)}
                                    />
                                </ActionStyles>
                            </ToolTip>
                        )}
                        {contractorPayment?.status === 'pending' && canEdit ? (
                            <>
                                <ToolTip
                                    text={`Edit Payment`}
                                    side="right"
                                    cssStyles={css`
                                        right: 42px !important;
                                        top: 0 !important;
                                    `}
                                >
                                    <ActionStyles>
                                        <BsPencil
                                            className="icon edit"
                                            onClick={() => setShowContractorPayment(contractorPayment, 'edit')}
                                        />
                                    </ActionStyles>
                                </ToolTip>

                                <ToolTip
                                    text={`Delete Payment`}
                                    side="right"
                                    cssStyles={css`
                                        right: 42px !important;
                                        top: 0 !important;
                                    `}
                                >
                                    <ActionStyles>
                                        <BsTrash
                                            className="icon delete"
                                            onClick={() => setShowContractorPayment(contractorPayment, 'delete')}
                                        />
                                    </ActionStyles>
                                </ToolTip>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(ContractorPaymentRow);
