import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import { css } from 'twin.macro';
import FileSaver from 'file-saver';
import { fetchWrapper } from '../../../../../../_helpers';

const DownloadReport = ({ slug, companyID, downloadLink, onGenerateLink, disabled, mediaType = 'pdf' }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [jobId, setJobId] = useState(downloadLink ? new URL(downloadLink).searchParams.get('job_id') : null);
    const timeoutRef = useRef(null);
    const isPolling = useRef(false);
    const maxRetries = 5;
    const retryInterval = 5000;

    const saveFile = (jobId) => {
        try {
            FileSaver.saveAs(downloadLink, `${slug}-${jobId || new Date().toISOString()}.${mediaType}`);
        } catch (err) {
            console.log('err', err);
        } finally {
            setIsBusy(false);
        }
    };

    const checkJobStatus = async (retryCount = 0) => {
        try {
            if (!jobId) {
                return;
            }
            setIsBusy(true);
            const jobArr = await fetchWrapper.get(
                `evv/payroll/zeal/provider/${companyID}/reports/status?jobId=${jobId}`
            );
            const foundJob = jobArr.find((job) => job?.job_id === jobId);

            if (foundJob.status === 'complete') {
                saveFile(jobId);
            } else if (['pending', 'in-progress'].includes(foundJob.status)) {
                if (retryCount < maxRetries) {
                    timeoutRef.current = setTimeout(() => {
                        if (isPolling.current) {
                            checkJobStatus(retryCount + 1);
                        }
                    }, retryInterval);
                } else {
                    toast.warn('The report is taking longer than expected. Please try again later.');
                    setIsBusy(false);
                }
            } else {
                throw new Error(foundJob?.payload?.data || 'Download failed. Please try again.');
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            toast.error(err.message || err);
            console.log('Error checking job status:', err);
            setIsBusy(false);
        }
    };

    const startDownloadPolling = () => {
        isPolling.current = true;
        setIsBusy(true);
        checkJobStatus();
    };

    const stopDownloadPolling = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        isPolling.current = false;
        setIsBusy(false);
    };
    const handleGenerateReport = async () => {
        setIsBusy(true);
        try {
            await onGenerateLink();
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            toast.error(err.message || 'Error generating report. Please try again.');
            console.log('Error generating report:', err);
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {
        const jobId = downloadLink ? new URL(downloadLink).searchParams.get('job_id') : null;
        if (jobId) {
            setJobId(jobId);
        } else {
            stopDownloadPolling();
        }
    }, [downloadLink]);
    useEffect(() => {
        if (jobId) {
            startDownloadPolling();
        }
        return () => {
            stopDownloadPolling();
        };
    }, [jobId]);

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 1rem;
            `}
        >
            <Button
                onClick={() => (!downloadLink ? handleGenerateReport() : startDownloadPolling())}
                disabled={disabled || isBusy}
                css={css`
                    cursor: ${isBusy ? 'wait !important' : ''};
                `}
            >
                {isBusy && <VscLoading className="spin mr-2" />}
                {!downloadLink ? 'Generate Report' : 'Download Report'}
            </Button>
        </div>
    );
};

export default DownloadReport;
