import React from 'react';
// import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';

const Email = ({ data, errors, isPreview, error, isDisabled, elementRef }) => (
    <Form.Group controlId={data.name || data._id}>
        <Form.Label>
            <h5 className="warpIt">
                {data.fieldName}
                <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
            </h5>

            {data.instruction && (
                <pre className="small text-muted warpIt preStyles">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        {data.instruction}
                    </Linkify>
                </pre>
            )}
        </Form.Label>
        <Form.Control
            type="email"
            name={!isPreview ? `${data.name || `Email-${data._id}`}` : 'Email'}
            ref={
                elementRef &&
                elementRef({
                    required: data.hasOwnProperty('required') ? data.required : true,
                    ...(data.validation || {}),
                })
            }
            disabled={isPreview || data.value || data.isDisabled || isDisabled}
            defaultValue={data.defaultValue ?? data.value}
            maxLength={data.maxLength}
            placeholder={data.placeholder}
        />
        <Form.Text className="text-danger">
            {error && (error.type === 'required' ? error.message || 'This field is required' : error.message)}
        </Form.Text>
        <Form.Text className="text-danger">
            {errors && data && errors[`Email-${data.name || data._id}`]?.message}
        </Form.Text>
    </Form.Group>
);

Email.propTypes = {};

export default Email;
